const styles = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '34px'
  },
  otpInput: {
    borderRadius: 4,
    width: '100%',
    height: 40,
    fontWeight: 700,
    fontSize: '18px',
    border: 'solid',
    borderColor: '#333333',
    borderWidth: 1,
  },
  otpResendText: (t) => ({color: t.palette.secondary.main}),
  otpFocus: (t) => ({borderColor: t.palette.primary.main, outline: 'none'}),
  resendBtn: {
    cursor: 'pointer'
  },
  mt8: {
    marginTop: '8px'
  },
  mt30: {
    marginTop: '30px'
  }
}

export default styles;