import React, {createContext, useRef} from 'react';

export const GlobalStoreContext = createContext({});

export const GlobalStoreProvider = ({children}) => {
  const store = useRef({})

  const updateStore = (value) => {
    const prevStore = {...store.current};
    const updatedStore = {...prevStore, ...value}
    store.current = updatedStore;
  }

  const getStore = (key) => {
    if(key){
      return store.current[key];
    }
    return store.current
  }

  const clearStore = () => {
    store.current = {}
  }
  return (
    <GlobalStoreContext.Provider value={{getStore, updateStore, clearStore}}>
      {children}
    </GlobalStoreContext.Provider>
  )
}
