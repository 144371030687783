const commonStyles = {
  progressBar: (t) => ({
    borderRadius: '5px',
    background: t.palette.primary.light
  }),
  p0: {
    padding: '0px',
  },
  px20: {padding: '0px 20px'},
  px10: {padding: '0px 20px'},
  mb10:{
    marginBottom: '10px'
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  },
  textCenter: {
    textAlign: 'center'
  }
}

export default commonStyles