import {stateList, isValidStateCode} from './stateData';

export function validVRN() {
  return this.test('validVRN', function (value = '') {
    const {path, createError} = this;
    if (value.length < 7) {
      return createError({path, message: 'VRN should be between 7-12 characters'});
    }

    if (value.substring(2, 4) === 'BH') {
      if (!/^[2-9][0-9]BH[0-9]{4}[A-Z]{1,2}$/.test(value)) {
        return createError({path, message: 'Please enter valid BH series vehicle registration number'});
      }
      return true;
    }

    if (!isValidStateCode(value.substring(0, 2), stateList)) {
      return createError({path, message: 'Please enter valid state code in vehicle registration number'});
    }
    if (!/^[A-Z]{2}[A-Z0-9]{1,3}[A-Z0-9]{1,3}[\d]{4}$/.test(value)) {
      return createError({path, message: 'Please enter valid vehicle registration number'});
    }
    return true;
  });
}

export function validPAN() {
  return this.test('validPAN', function (value = '') {
    const {path, createError} = this;
    if (value.length < 10) {
      return createError({path, message: 'PAN should be of 10 characters'});
    }
    const panPartFirst3 = value.substring(0, 3);
    const panPart4 = value.charAt(3);
    const panPart5 = value.charAt(4);
    const panPart6_9 = value.substring(5, 9);
    const panPart10 = value.charAt(9);
    const panPart5Regex = new RegExp(`^[A-Z]$`);
    if (!/^[A-Z]{3}$/.test(panPartFirst3)) {
      return createError({path, message: 'The first 3 characters must be alphabetic series ranging between AAA and ZZZ'});
    }
    if (!/^[PCHABGJLFT]$/.test(panPart4)) {
      return createError({path, message: 'The 4th character must be an alphabet among C,P,H,A,B,G,J,L,F,T'});
    }
    if (!panPart5Regex.test(panPart5)) {
      return createError({path, message: 'The 5th character must be an alphabet that runs from A to Z'});
    }
    if (!/^[\d]{4}$/.test(panPart6_9)) {
      return createError({path, message: 'The 6th to 9th characters must be sequential digits ranging between 0001 and 9999'});
    }
    if (!/^[A-Z]$/.test(panPart10)) {
      return createError({path, message: 'The 10th character must be an alphabet that runs from A to Z'});
    }
    return true;
  });
}
