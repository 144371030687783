import {Grid, Typography} from '@mui/material';
import iconLogo from './images/nu_logo.svg';
import footerGraphics from './images/footer_graphic.svg';
import styles from './styles';

const AppFooter = () => {
  return (
    <Grid item xs={12}>
      <Grid container alignItems="flex-end" justifyContent="center" sx={styles.footerWrapper}>
        <Grid item xs={12}>
          <Grid container direction="column">
            <Grid item sx={styles.alignCenter}>
              <Typography sx={styles.poweredBy} color="textSecondary">Powered by</Typography>
              <img src={iconLogo} width={60} alt="logo"/>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={[styles.footer, {backgroundImage: `url(${footerGraphics})`}]} item xs={12}/>
      </Grid>
    </Grid>
  )
}

export default AppFooter;