import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material"; // Updated import
import { styled } from "@mui/material/styles"; // Import styled from MUI
import FileUpload from "./FileUpload";
import imageCompression from "browser-image-compression"; // Import image compression library
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import Button from "../../UIKit/Button";
import { uploadRC } from "../../api";
import withStore from '../../globalStore/withStore';

// Styled components using styled API
const FormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: 600,
  margin: "auto",
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: "700",
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: "600",
}));

const SubtitleText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: "400",
  fontSize: "11px",
  color: "#003666",
}));

const Disclaimer = styled(Typography)(({ theme }) => ({
  color: "red",
  fontSize: 12,
  marginBottom: theme.spacing(2),
  fontWeight: "500",
}));

const UploadSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const FormFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(2),
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FF8A00",
  color: "white",
  padding: theme.spacing(1, 5),
  "&:hover": {
    backgroundColor: "#FF8A00",
  },
}));

const uploadErrors = {
  JPEG_ERROR: "Image should be of type jpeg/jpg",
  SIZE_ERROR: "Image should be less than 8 MB",
  COMPRESSION_ERROR: "Image compression error. Please try again",
  UPLOAD_ERROR: "Image could not be uploaded",
};

const UploadRC = ({ getStore, updateStore}) => {
    const storeData = getStore();
  const [loading, setLoading] = useState(false);
  const [uploadFileFront, setUploadFileFront] = useState(
    storeData?.rcImageFront || ""
  );
  const [uploadFileBack, setUploadFileBack] = useState(storeData?.rcImageBack || "");
  const [error, setError] = useState("");

  const navigate = useNavigate(); // Initialize the useNavigate hook

  const compressImage = async (file) => {
    if (!file) return file;

    const fileSizeMb = file.size / 1024 / 1024;
    if (fileSizeMb > 0.8) {
      // Compress only if the file is larger than 0.8 MB
      const options = {
        maxSizeMB: 0.8,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        return compressedFile;
      } catch (error) {
        // console.error('Compression error:', error);
        setError(uploadErrors.COMPRESSION_ERROR);
        return file; // Return the original file if compression fails
      }
    } else {
      return file; // Return the original file if it's already below the threshold
    }
  };

  const handleFileChangeFront = async (value) => {
    if (value && value.file) {
      const compressedFile = await compressImage(value.file);
      setUploadFileFront({ ...value, file: compressedFile });
    } else {
      setUploadFileFront(value);
    }
    setError(""); // Clear error when file changes
  };

  const handleFileChangeBack = async (value) => {
    if (value && value.file) {
      const compressedFile = await compressImage(value.file);
      setUploadFileBack({ ...value, file: compressedFile });
    } else {
      setUploadFileBack(value);
    }
    setError(""); // Clear error when file changes
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const {issuanceParams: {amazon_ref}} = storeData;
    // Check if both files are uploaded
    if (!uploadFileFront || !uploadFileBack) {
      setError("Both front and back sides of the RC document are required");
      return;
    }

    // Check if either of the files are directly captured or retake
    try {
      setLoading(true);
      const formData = new FormData();

      // Function to safely append file to FormData
      const appendFileToFormData = (key, fileData) => {
        // console.log(`Appending file data for ${key}:`, fileData); // Log file data

        if (fileData && fileData.file instanceof Blob) {
          formData.append(key, fileData.file, fileData.file.name);
        } else {
          throw new Error(`Invalid file data for ${key}`);
        }
      };

      // Append the front file to formData with specific filename
      appendFileToFormData("rcimg", uploadFileFront);

      // Append the back file to formData with specific filename
      appendFileToFormData('rcimg_back' , uploadFileBack);

      formData.append("amazon_ref", amazon_ref);

      const result = await uploadRC(formData);
      if (result) {
        // proceed(3, 4, 50, { isRcDocUploaded: true });
        navigate("/vehicle-tag-cost", { replace: true }); // Navigate to VehicleTagCost page
      }
    } catch (err) {
      // console.log('err', err);
      setError(err?.message || "Upload failed");
    } finally {
      setLoading(false); // Ensure loading is false after attempt
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <Title variant="h6">Please Upload RC documents</Title>
        <Disclaimer>
          FASTag can be deactivated if valid RC is not provided. Kindly ensure
          the same to avoid double penalty at Toll Plaza.
        </Disclaimer>
        <Subtitle variant="body1">Upload front side</Subtitle>
        <SubtitleText>
          Documents must be original copy only and not photo copy or mobile
          image.
        </SubtitleText>

        <UploadSection>
          <FileUpload
            retakeText="Retake"
            cameraText="Use the camera to capture the document"
            retryText="Retry"
            okText="OK"
            innerLabel="Front Side"
            value={uploadFileFront}
            error={error}
            onChange={handleFileChangeFront}
          />
        </UploadSection>
        <Subtitle variant="body1">Upload back side</Subtitle>
        <SubtitleText>
          Documents must be original copy only and not photo copy or mobile
          image.
        </SubtitleText>
        <UploadSection>
          <FileUpload
            retakeText="Retake"
            cameraText="Use the camera to capture the document"
            retryText="Retry"
            okText="OK"
            innerLabel="Back Side"
            value={uploadFileBack}
            error={error}
            onChange={handleFileChangeBack}
          />
        </UploadSection>
      </FormContainer>

      <FormFooter>
        <Button
          type="submit"
          loading={loading}
        >
          {uploadFileBack && uploadFileFront
            ? "Submit RC documents"
            : "Continue"}
        </Button>
      </FormFooter>
    </form>
  );
};

export default withStore(UploadRC);
