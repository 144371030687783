import {Card, CardContent, Grid, Typography} from '@mui/material';
import CopyClipboardButton from '../../../components/CopyClipboardButton';

const CustomerIDView = ({tpCustomerId}) => (
  <Card sx={styles.customerIdCard}>
    <CardContent sx={{paddingBottom: '8px', marginTop: '20px'}}>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Typography sx={styles.custIdLabel} variant="caption">
            Customer ID
          </Typography>
          <Typography sx={styles.custIdText} variant="h2">
            {tpCustomerId}
            <CopyClipboardButton size={20} color="white" text={tpCustomerId}/>
          </Typography>
          <Typography sx={styles.custIdCaption} variant="caption">
            Use this to login to your FASTag account on:
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
)

const styles = {
  customerIdCard: (t) => ({
    boxShadow: 'none',
    backgroundColor: t.palette.primary.main,
    borderRadius: '10px',
    marginTop: '-20px',
  }),
  custIdLabel: (t) => ({
    color: t.palette.font.inputBtnText,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 12,
    marginTop: 30,
  }),
  custIdText: (t) => ({
    color: t.palette.font.inputBtnText,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 36,
    fontWeight: 700,
    alignItems: 'center',
  }),
  custIdCaption: (t) => ({
    color: t.palette.font.inputBtnText,
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '5px',
    fontSize: '12px',
  }),
}

export default CustomerIDView