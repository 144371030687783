import {Box, Grid, Typography} from '@mui/material';
import NoteDescription from '../../../components/NoteDescription';

import styles from '../styles';
import carIcon from '../images/car.svg';
import alertIcon from '../images/alert.svg';

const Notes = () => (
  <Grid xs={12} item>
    <Box sx={styles.noteSection}>
      <Typography sx={styles.noteSectionHeader}>NOTE:</Typography>
      <NoteDescription
        image={carIcon}
        text="This tag application is only for passenger car vehicles."
      />
      <NoteDescription
        image={alertIcon}
        text="Purchase of FASTag for heavy vehicles is not supported at the moment"
      />
      <NoteDescription
        image={alertIcon}
        text="The tag issued needs to be affixed only on the vehicle it is being applied for."
      />
    </Box>
  </Grid>
)

export default Notes;