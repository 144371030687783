const styles = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '34px'
  },
  noteSection: (t) => ({
    background: t.palette.secondary.lighter,
    borderRadius: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '15px',
    marginTop: '15px',
    marginBottom: '15px',
  }),
  noteSectionHeader: {
    color: '#333333',
    letterSpacing: '0.02em',
    fontSize: '12px',
    fontWeight: 700,
    marginBottom: '10px'
  }
}

export default styles;