import {RadioGroup, FormControlLabel, Radio} from '@mui/material';

const ButtonGroupInput = ({row, value, options, onChange, disabled}) => {
  return (
    <RadioGroup
      row={row}
      value={value}
      onChange={(ev) => onChange(ev.target.value)}
    >
      {options.map((d) => (
        <FormControlLabel
          key={d.label}
          value={d.value}
          disabled={disabled}
          control={<Radio color="secondary"/>}
          label={d.label}
          sx={styles.formControlLabel}
        />
      ))}
    </RadioGroup>
  );
};

const styles = {
  formControlLabel: {
    formControlLabel: {
      '& .MuiTypography-root': {
        fontSize: 14,
      },
    },
  }
};

export default ButtonGroupInput;
