import {Grid, Typography} from '@mui/material';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '15px',
  },
  text: {
    fontSize: '12px',
    color: '#333333',
    paddingLeft: '10px',
  },
}

const NoteDescription = ({image, text}) => {
  return (
    <Grid sx={styles.root} item container>
      <Grid
        xs={1}
        justifyContent="flex-start"
        alignItems="flex-end"
        alignContent="flex-end"
        item
        container
      >
        <img src={image} alt="icon" width="100%"/>
      </Grid>
      <Grid xs={11} item>
        <Typography sx={styles.text}>{text}</Typography>
      </Grid>
    </Grid>
  );
};

export default NoteDescription;
