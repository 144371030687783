import {rpKey, rpConfig, rpTheme, rpTitle, rpImage} from '../config/razorpay';

export const initPG = ({
  amount,
  sourceApp,
  orderID,
  name,
  email,
  contact,
  handlePaymentSuccess,
  handlePaymentFailed,
}) => {
  const options = {
    key: rpKey(sourceApp),
    amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: 'INR',
    name: rpTitle(sourceApp),
    image: rpImage,
    order_id: orderID,
    handler: handlePaymentSuccess,
    prefill: {name, email, contact},
    notes: {base_amount: amount},
    theme: rpTheme,
    config: rpConfig,
  }
  // eslint-disable-next-line no-undef
  const rzp = new Razorpay(options);
  rzp.on('payment.failed', handlePaymentFailed);
  rzp.open();
}
