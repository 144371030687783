import axios from 'axios';

const PINCODE_API = 'https://t9agoemj84.numadic.com/pincodes';

const pinCodeDetails = (payload) => {
  const axiosParams = {
    method: 'GET',
    url: PINCODE_API,
    params: payload
  }
  return new Promise((resolve, reject) => {
    axios(axiosParams)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  })
}

export default pinCodeDetails;