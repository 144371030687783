import {apiFT} from '../services/rest';

export const verifyURL = (payload) => apiFT('/verify/url', 'POST', payload)
export const sendOTP = (payload) => apiFT('/send-otp', 'GET', payload)
export const verifyOTP = (payload) => apiFT('/verify-otp', 'POST', payload)

export const tagCost = (payload) => apiFT('/tag-cost', 'GET', payload)
export const vehicleMake = (payload) => apiFT('/vehicle-make', 'GET', payload)
export const vehicleModel = (payload) => apiFT('/vehicles', 'GET', payload)

export const uploadRC = (payload) => apiFT('/upload-rcdoc', 'POST', payload)
export const saveVehicleDetails = (payload) => apiFT('/vehicle-details', 'POST', payload)
export const savePersonalDetails = (payload) => apiFT('/personal-details', 'POST', payload)
export const saveDeliveryAddressDetails = (payload) => apiFT('/address-details', 'POST', payload)

export const approveTnC = (payload) => apiFT('/accept-tc-consent', 'POST', payload)
export const createCustomer = (payload) => apiFT('/customer', 'POST', payload)

export const pgOrderID = (payload) => apiFT('/pg/initiate', 'POST', payload)
export const pgStatus = (payload) => apiFT('/pg/callback', 'POST', payload)
export const txnStatus = (payload) => apiFT('/pg/transaction/status', 'GET', payload)


