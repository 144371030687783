import {useEffect, useState} from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid, LinearProgress,
  Typography
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import withStore from '../../globalStore/withStore';

import StepsHeader from '../../components/StepsHeader';
import styles from './styles';
import TagCostVRN from '../../components/TagCostVRN';
import AutocompleteInput from '../../UIKit/AutocompleteInput';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import Spacer from '../../UIKit/Spacer';
import ButtonGroupInput from '../../UIKit/RadioGroupInput';
import Button from '../../UIKit/Button';
import FileInput from '../../UIKit/FileInput';
import {saveVehicleDetails, tagCost, uploadRC, vehicleMake, vehicleModel} from '../../api';
import commonStyles from '../../UIKit/commonStyles';
import TextInput from '../../UIKit/TextInput';

const initialValues = {
  make: {},
  makeKey: '',
  makeLabel: '',
  model: {},
  modelKey: '',
  modelLabel: '',
  vehicleCategory: 'private',
  chassis_number: '',
  engine_number: '',
};

const vehicleCategoryOptions = [
  {
    value: 'private',
    label: 'Private'
  },
  {
    value: 'commercial',
    label: 'Commercial / Taxi'
  }
];

const VehicleTagCost = ({getStore, updateStore}) => {
  const storeData = getStore();
  const [loading, setLoading] = useState(false);
  const [loadingVehicleModel, setLoadingVehicleModel] = useState(false);
  const [vehicleMakeList, setVehicleMakeList] = useState([]);
  const [vehicleModelList, setVehicleModelList] = useState([]);
  const [tagTotalAmount, setTagTotalAmount] = useState(null);
  const navigate = useNavigate();
  const alphaNumericWithSpecial = /^[A-Za-z0-9*~]*$/;

  const validationSchema = Yup.object().shape({
    make: Yup.object().nullable(),
    model: Yup.object().nullable(),
    makeKey: Yup.string().nullable().required('Mandatory field!'),
    makeLabel: Yup.string().nullable().required('Mandatory field!'),
    modelKey: Yup.string().nullable().required('Mandatory field!'),
    modelLabel: Yup.string().nullable().required('Mandatory field!'),
    vehicleCategory: Yup.string().nullable().required('Mandatory field!'),
    chassis_number: Yup.string()
      .nullable()
      .required('Chassis number is required')
      .min(17, 'Chassis number must be at least 17 characters')
      .max(32, 'Chassis number can be at most 32 characters')
      .matches(
        alphaNumericWithSpecial,
        'Chassis number contains invalid characters'
      ),
    engine_number: Yup.string()
      .nullable()
      .required('Engine number is required')
      .min(6, 'Engine number must be at least 6 characters')
      .max(30, 'Engine number can be at most 30 characters')
      .matches(
        alphaNumericWithSpecial,
        'Engine number contains invalid characters'
      ),
  });
  

  useEffect(() => {
    getTagCost({vehicleClass: '4'});
    getVehicleMake()

    const {vrnDetails} = storeData;
    if(vrnDetails?.vehicleMake){
      const {vehicleMake, vehicleModel} = vrnDetails;
      setFieldValue('make', {
        label: vehicleMake.MakeDescription,
        key: vehicleMake,
      })
      setFieldValue('makeLabel', vehicleMake.MakeDescription)
      setFieldValue('makeKey', vehicleMake?.MakeCode)

      setFieldValue('model', {
        label: vehicleModel?.ModelDescription,
        key: vehicleModel,
      })
      setFieldValue('modelLabel', vehicleModel?.ModelDescription)
      setFieldValue('modelKey', vehicleModel?.Model)
    }
    if(vrnDetails?.rcVerified){
      setFieldValue('uploadRC', 'verified')
    }
  }, [])

  const getTagCost = async () => {
    try{
      const {GSTAmount, TagIssuanceFee, TagSecurityDeposit, TagTotalAmount, TagUploadAmount} = await tagCost();
      updateStore({
        tagCost: {
          gstAmount: GSTAmount,
          tagIssuanceFee: TagIssuanceFee,
          tagSecurityDeposit: TagSecurityDeposit,
          tagTotalAmount: TagTotalAmount,
          tagUploadAmount: TagUploadAmount,
        }
      })
      setTagTotalAmount(TagTotalAmount)
    }catch (err){
    }
  }

  const getVehicleModels = async (value) => {
    if(!value) return;
    try{
      setLoadingVehicleModel(true);
      const {VehicleModels} = await vehicleModel({vehicleMake: value.key.MakeCode});
      setLoadingVehicleModel(false);
      setVehicleModelList(VehicleModels.map((item) => ({label: item.ModelDescription, key: item})))
    }catch (err){
      setLoadingVehicleModel(false);
    }
  }

  const getVehicleMake = async () => {
    try{
      const response = await vehicleMake();
      setVehicleMakeList(response.map((item) => ({label: item.MakeDescription, key: item})))
    }catch (err){
    }
  }

  const handleVehicleMakeChange = (v) => {
    setFieldValue('make', v);
    setFieldValue('model', {});
    setFieldValue('makeLabel', v?.label ?? null);
    setFieldValue('makeKey', v?.key?.MakeCode ?? null);
    setFieldValue('modelLabel', '');
    setFieldValue('modelKey', '');
    getVehicleModels(v)
  }

  const handleVehicleModelChange = (v) => {
    setFieldValue('model', v);
    setFieldValue('modelLabel', v?.label ?? null);
    setFieldValue('modelKey', v?.key?.Model ?? null);
  }




  const handleSubmit = async (values) => {
    const vehicleDetails = {
      vehicleCategory: values.vehicleCategory,
      vehicleMake: values.make?.key,
      vehicleModel: values.model?.key,
      chassis_number: values.chassis_number,
      engine_number:values.engine_number,
      amazonRef: storeData.issuanceParams?.amazon_ref
    }
    setLoading(true)
    try{
      await saveVehicleDetails(vehicleDetails);
      updateStore({vehicleDetails})
      setLoading(false)
      navigate('/personal-info', {replace: true})
    }catch (err){
      setLoading(false)
    }
  };

  const {vrn} = storeData?.applicationDetails ?? {};
  const {rcVerified} = storeData?.vrnDetails ?? {};
  const {values, errors, submitCount, touched,setFieldValue, submitForm} = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const formTouched = submitCount > 0;


    return (
      <Container maxWidth="xs" sx={styles.root}>
        <LinearProgress sx={commonStyles.progressBar} variant="determinate" color="primary" value={40} />
        <Grid container direction="row" justifyContent="center">
          <StepsHeader text="What is the type of your vehicle?" />
    
          <Card variant="outlined" sx={{ width: '100%', marginBottom: '30px' }}>
            <TagCostVRN
              loading={false}
              fastagPrice={tagTotalAmount}
              rcNumber={vrn}
            />
    
            <CardContent>
              <Grid direction="column" container spacing={3}>
                <Grid item>
                  <AutocompleteInput
                    label="Search Vehicle Make"
                    required
                    error={formTouched && errors.makeKey}
                    value={values.make}
                    options={vehicleMakeList}
                    onChange={handleVehicleMakeChange}
                  />
                  <Spacer space={12} />
                  <AutocompleteInput
                    label="Search Vehicle Model"
                    required
                    disabled={!values?.make?.label}
                    options={vehicleModelList}
                    loading={loadingVehicleModel}
                    error={formTouched && errors.modelKey}
                    value={values.model}
                    onChange={handleVehicleModelChange}
                  />
                  <Spacer space={12} />
                  <TextInput
                    label="Chassis Number"
                    required
                    error={(formTouched || touched.chassis_number) && errors.chassis_number}
                    value={values.chassis_number}
                    maxLength={32}
                    toUpperCase
                    onChange={(v) => setFieldValue('chassis_number', v)}
                  />
                  <Spacer space={12} />
                  <TextInput
                    label="Engine Number"
                    required
                    error={(formTouched || touched.engine_number) && errors.engine_number}
                    value={values.engine_number}
                    maxLength={30}
                    toUpperCase
                    onChange={(v) => setFieldValue('engine_number', v)}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Typography sx={styles.typeVehicle}>
              Select the type of vehicle
            </Typography>
            <CardActions sx={{ padding: '8px 16px' }}>
              <ButtonGroupInput
                row
                value={values.vehicleCategory}
                onChange={v => setFieldValue('vehicleCategory', v)}
                options={vehicleCategoryOptions}
              />
            </CardActions>
          </Card>
          {/* {
            !rcVerified && (
              <Grid item style={{ marginBottom: 30 }}>
                <Typography sx={styles.uploadRCText}>
                  RC number could not be verified against our records. Please upload an
                  image of your RC document for verification
                </Typography>
    
                <FileInput
                  onFileUpload={handleFileSelect}
                  maxSizeInMB={8}
                  hasError={formTouched && errors.uploadRC}
                  allowedFileTypes={['image/jpg', 'image/jpeg', 'image/png']}
                  label="Upload vehicle RC document"
                  successText="Copy of RC image uploaded successfully!"
                  helperText=" Max size: 8 MB | Only .jpeg, .jpg, .png formats are supported"
                />
              </Grid>
            )
          } */}
    
          <Spacer space={24} />
    
          <Button loading={loading} onClick={submitForm}>Continue</Button>
        </Grid>
      </Container>
    );
};

export default withStore(VehicleTagCost);