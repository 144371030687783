import {Grid, Typography} from '@mui/material';

const DispatchInProcess = () => (
  <Grid container>
    <Grid item xs={12}>
      <Typography sx={styles.processingText} variant="h6">
        Processing
      </Typography>
      <Typography sx={styles.deliveryDateTitle}>
        Expected delivery
      </Typography>
      <Typography sx={styles.deliveryDateText} variant="h6">
        Within 5-7 working days
      </Typography>
    </Grid>
  </Grid>
)

const styles = {
  processingText: (t) => ({
    color: t.palette.font.main,
    paddingBottom: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  }),
  deliveryDateTitle: (t) => ({
    color: t.palette.font.main,
    opacity: 0.5,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: '10px',
    fontSize: '12px',
    width: '100%',
    textAlign: 'center',
  }),
  deliveryDateText: (t) => ({
    color: t.palette.font.main,
    paddingBottom: '5px',
    fontSize: '12px',
    fontWeight: 700,
    width: '100%',
    textAlign: 'center',
  }),
}

export default DispatchInProcess;