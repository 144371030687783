import {ThemeProvider} from '@mui/material';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import theme from './UIKit/theme';
import AppRoutes from './AppRoutes';
import {GlobalStoreProvider} from './globalStore';

const cache = createCache({
    key: 'amz-issuance',
    nonce: 'amz-issuance-csp',
    prepend: true,
});

function App() {
  return (
    <GlobalStoreProvider>
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <AppRoutes/>
          </ThemeProvider>
        </CacheProvider>
    </GlobalStoreProvider>
  );
}

export default App;

