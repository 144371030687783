const styles = {
  footerWrapper: {
    height: '30vh'
  },
  alignCenter: {textAlign: 'center'},
  poweredBy: {
    textAlign: 'center',
    fontSize: '10px',
    marginBottom: '10px',
  },
  footer: {
    height: '10vh',
    width: '100%',
    bottom: 0,
    backgroundRepeat: 'repeat-x',
  }
}

export default styles;