import {createTheme} from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    h1: {
      fontSize: 48,
      '@media (max-width:960px)': {
        fontSize: 24,
      },
      fontWeight: 800,
    },
    h2: {
      fontSize: 32,
      '@media (max-width:960px)': {
        fontSize: 18,
      },
      fontWeight: 600,
    },
    h3: {
      fontSize: 28,
      '@media (max-width:960px)': {
        fontSize: 16,
      },
      fontWeight: 600,
    },
    h4: {
      fontSize: 24,
      '@media (max-width:960px)': {
        fontSize: 14,
      },
      fontWeight: 600,
    },
    h5: {
      fontSize: 22,
      '@media (max-width:960px)': {
        fontSize: 14,
      },
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 18,
      '@media (max-width:960px)': {
        fontSize: 14,
      },
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 16,
      '@media (max-width:960px)': {
        fontSize: 12,
      },
      fontWeight: 400,
    },
    body1: {
      fontSize: 14,
      '@media (max-width:960px)': {
        fontSize: 12,
      },
      fontWeight: 400,
    },

  },
  palette: {
    appbar: {
      form: '#333333',
    },
    font: {
      primary: '#333333',
      secondary: '#FFFFFF',
      inputBtnText: '#FFFFFF',
    },
    primary: {
      main: '#FF9900',
      light: '#FEF7EE',
      mainGradient: 'linear-gradient(192.09deg, #FF9836 7.79%, #FF7B05 108.16%)',
      hover: '#103F93',
      icici: '#FF9900',
    },
    secondary: {
      main: '#185FDC',
      light: '#2F6FE0',
      lighter: '#E0F0FF',
    },
    msgChip: {
      main: '#FFD467',
    },
    border: {
      main: 'rgba(0, 0, 0, 0.23)',
    },
    blue: '#004E98',
    green: '#2C9251',
    red: '#FF0000',
  },
});

export default theme;
