import {useContext, useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Box, Container} from '@mui/material';
import queryString from 'query-string';

import AppBar from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import SessionTimeout from '../components/SessionTimeout';
import AmazonIssuanceInfo from '../components/AmazonIssuanceInfo';
import {clearStorage, getStorageItem, setStorageBulk, setStorageItem} from '../utils/storage';
import {GlobalStoreContext} from '../globalStore';
import {verifyURL} from '../api';
import {idTypeMetaMap} from './NewApplication/config';
import MaintenancePopup from '../components/MaintenancePopup';

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {updateStore, getStore} = useContext(GlobalStoreContext);
  const [sessionTime, setSessionTime] = useState(0)
  const [maintenancePopup, setMaintenancePopup] = useState(false);
  const [sourceApp, setSourceApp] = useState('');

  useEffect(() => {
    setMaintenancePopup(process.env.REACT_APP_MAINTENANCE === "ENABLED" ? true : false);
    const { utm_appRefNo, utm_timeout, utm_source, utm_medium, utm_campaign } = queryString.parse(location?.search);
    let issuanceParams;
    if(utm_source && utm_timeout){
      issuanceParams = {utm_appRefNo, utm_timeout, utm_source, utm_medium, utm_campaign}
      clearStorage()
      setStorageBulk({issuanceParams: issuanceParams})
    }else{
      issuanceParams = getStorageItem('issuanceParams', true)
      clearStorage()
      setStorageBulk({issuanceParams: issuanceParams})
    }
    const source = issuanceParams?.utm_source?.toLowerCase();
    if(source){
      issuanceParams?.utm_timeout && setSessionTime(Number(issuanceParams.utm_timeout));
      setSourceApp(source);
      handleURLVerification(issuanceParams);
    }else{
      navigate('/failure', { replace: true})
    }

    window.addEventListener('tooManyRequests', redirectFailureTooManyReq)
    return () => {
      window.removeEventListener('tooManyRequests', redirectFailureTooManyReq);
    }
  // eslint-disable-next-line
  }, []);


  const redirectFailureTooManyReq = (ev) => {
    const {issuanceParams : { utm_source }} = getStore();
    const {message} = ev.detail;
    navigate('/failure', {
      replace: true,
      state: {
        sourceApp: utm_source.toLowerCase(),
        errorMessage: message ?? 'Something went wrong. Please try again!'
      }
    })
  }

  const handleURLVerification = async (urlParams) => {
    const { utm_appRefNo, utm_timeout, utm_source, utm_medium, utm_campaign } = urlParams;
    try{
      const issuanceParams = {
        amazon_ref_expiry: utm_timeout,
        amazon_ref: utm_appRefNo.toUpperCase(),
        utm_source: utm_source,
        utm_medium,
        utm_campaign
      }
      const {data} = await verifyURL(issuanceParams)
      if (window.clarity) {
        // eslint-disable-next-line no-undef
        clarity('set', 'amazon_ref', utm_appRefNo);
      }
      let applicationDetails = null;
      let customerID = null;
      if (data?.pan_number) {
        const {pan_number, rc_number, id_type, id_number, mobile_no, customer_id} = data;
        applicationDetails = {
          pan: pan_number,
          vrn: rc_number,
          idType: idTypeMetaMap[id_type.toUpperCase()],
          idNumber: id_number,
          mobile: mobile_no
        };
        customerID = customer_id;
      }
      updateStore({
        customerID,
        applicationDetails: applicationDetails,
        issuanceParams: issuanceParams
      })
      setStorageItem('urlVerified', 'YES')
      navigate('/new-application', { replace: true})
    }catch (e){
      navigate('/failure', { replace: true, state: {sourceApp: utm_source.toLowerCase(), errorMessage: e?.msg}})
    }
  }

  return (
    <div>
      <AppBar sourceApp={sourceApp}/>
      <MaintenancePopup open={maintenancePopup} handleClose={() => {}}/>
      <SessionTimeout sessionTime={sessionTime}/>

      {
        sourceApp === 'amazon' && <AmazonIssuanceInfo/>
      }

      <Container maxWidth="xs" fixed>
        <Box sx={styles.mainContainer} >
          <Outlet/>
        </Box>
      </Container>
      <AppFooter/>
    </div>
  )
}


const styles = {
  timerContainer: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#FFF4E4',
    color: '#333333',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  mainContainer: (t) => ({
    marginTop: '20px',
  })
}

export default Layout;