const env = process.env.REACT_APP_ENV;

const key = {
  production: {
    etoll: 'rzp_live_5NrOP18Zh5T9XT',
    amazon: 'rzp_live_5vCj00paGdeqS1',
  },
  development: {
    etoll: 'rzp_test_OBMPxyHkW2Kfw0',
    amazon: 'rzp_test_QDKWyNGX0onjdl',
  },
  local: {
    etoll: 'rzp_test_OBMPxyHkW2Kfw0',
    amazon: 'rzp_test_QDKWyNGX0onjdl',
  }
}

const title = {
  etoll: 'eToll ICICI FASTag',
  amazon: 'Amazon pay ICICI FASTag',
}

export const rpKey = (source = 'amazon') => key[env][source]

export const rpTitle = (source = 'amazon') => title[source]

export const rpConfig = {
  display: {
    blocks: {
      icici: {
        name: 'Pay using Amazon Pay',
        instruments: [
          {
            method: 'wallet',
            wallets: ['amazonpay'],
          },
          {
            method: 'upi',
          },
        ],
      },
      card: {
        name: 'Cards, Wallets and more',
        instruments: [{method: 'card'}, {method: 'upi'}, {method: 'netbanking'}, {method: 'wallet'}],
      },
    },
    sequence: ['block.icici', 'block.card'],
    preferences: {show_default_blocks: false},
  },
}

export const rpTheme = {color: '#FF9900'}

export const rpImage = 'https://image3.mouthshut.com/images/imagesp/925004492s.jpg';