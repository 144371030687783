import {Grid, Typography} from '@mui/material';
import CopyClipboardButton from '../../../components/CopyClipboardButton';

const ReferenceIDView = ({sourceApp, referenceID}) => (
  <Grid item xs={12} sx={{textAlign: 'center'}}>
    <Typography sx={styles.statusTitle}>
      {
        sourceApp === 'amazon' ? 'Amazon Reference ID' : 'Reference ID'
      }
    </Typography>
    <Typography sx={styles.orderIdText}>
      {referenceID}
      <CopyClipboardButton text={referenceID}/>
    </Typography>
  </Grid>
)

const styles = {
  statusTitle: (t) => ({
    color: t.palette.font.main,
    paddingTop: '10px',
    paddingBottom: '5px',
    opacity: 0.5,
    fontSize: '12px',
  }),
  orderIdText: (t) => ({
    color: t.palette.font.main,
    fontWeight: 'bold',
    fontSize: 18,
  }),
}

export default ReferenceIDView