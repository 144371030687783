import {useEffect, useRef, useState} from 'react';
import {Alert, Container, LinearProgress} from '@mui/material';
import {useFormik} from 'formik';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useLocation, useNavigate} from 'react-router-dom';

import withStore from '../../globalStore/withStore';
import {getStorageItem} from '../../utils/storage';
import {sendOTP} from '../../api';

import TextInput from '../../UIKit/TextInput';
import SelectInput from '../../UIKit/SelectInput';
import Button from '../../UIKit/Button';
import Spacer from '../../UIKit/Spacer';

import StepsHeader from '../../components/StepsHeader';

import ConfirmDetails from './subcomponent/ConfirmDetails';
import Notes from './subcomponent/Notes';

import {validationSchema, idTypeMeta, initialValues} from './config';

import styles from './styles';
import commonStyles from '../../UIKit/commonStyles';

const NewApplication = ({getStore, updateStore}) => {
  const location = useLocation();
  const {newApplicationError} = location?.state ?? {};
  const navigate = useNavigate();
  const storeData = getStore();
  const [customerID] = useState(storeData?.customerID);
  const captcha = useRef(null);
  const formData = useRef(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const {executeRecaptcha} = useGoogleReCaptcha();

  useEffect(() => {
    if(getStorageItem('urlVerified') !== 'YES'){
      return navigate('/failure', {replace: true})
    }
    if(newApplicationError){
      setError(newApplicationError)
    }
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(!dirty){
      storeData?.applicationDetails && setValues(storeData?.applicationDetails)
    }
  // eslint-disable-next-line
  }, [storeData?.applicationDetails])

  const handleReCaptchaVerify = () => {
    if (!executeRecaptcha) return;
    return executeRecaptcha('newApplicationIssuance' + String.fromCharCode(0|Math.random()*26+97));
  };

  const handleRegister = async () => {
    try{
      setError('');
      setLoading(true);
      const {issuanceParams : {amazon_ref} } = storeData;
      await sendOTP({
        mobile_no: formData.current.mobile,
        token: captcha.current,
        appRefNo: amazon_ref
      })
      updateStore({
        applicationDetails: formData.current,
      })
      navigate('/verify-otp');
    }catch (err){
      setShowConfirm(false);
      if(err?.message || err?.msg){
        setError(err?.message || err?.msg)
      }
    }finally {
      setLoading(false)
    }
  }

  const handleSubmit = async (values) => {
    const {customerID, issuanceParams : { utm_source }} = storeData;
    if(customerID){
      navigate(`/order-summary?type=existing&customer_uid=${customerID}&source_app=${utm_source?.toLowerCase()}`, {
        replace: true,
      })
    }
    if(!captcha.current){
      captcha.current = await handleReCaptchaVerify();
    }
    formData.current = {...values}
    setShowConfirm(true)
  };

  const {values, errors, dirty, touched, setFieldValue, setValues, submitForm} = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Container maxWidth="xs" sx={styles.root}>
      <LinearProgress sx={commonStyles.progressBar} variant="determinate" color="primary" value={10}/>

      <StepsHeader text="Please enter your details to continue"/>

      <SelectInput
        label="Select ID type"
        required
        error={touched.idType && errors.idType}
        value={values.idType}
        disabled={!!customerID}
        options={Object.keys(idTypeMeta).map((key) => ({label: idTypeMeta[key].label, value: idTypeMeta[key].key}))}
        onChange={(v) => {
          setFieldValue('idType', v)
          setFieldValue('idNumber', '')
        }}
      />

      <Spacer space={8}/>

      {
        values.idType && (
          <>
            <TextInput
              key={idTypeMeta[values.idType].key}
              label={idTypeMeta[values.idType].label}
              error={touched.idNumber && errors.idNumber}
              required
              value={values.idNumber}
              maxLength={idTypeMeta[values.idType].maxLength}
              toUpperCase
              pattern={idTypeMeta[values.idType].pattern}
              disabled={!!customerID}
              onChange={(v) => setFieldValue('idNumber', v)}
            />
            <Spacer space={8}/>
          </>
        )
      }

      <TextInput
        label="PAN"
        required
        error={touched.pan && errors.pan}
        value={values.pan}
        toUpperCase
        maxLength={10}
        disabled={!!customerID}
        pattern={/[^\w]/g}
        onChange={(v) => setFieldValue('pan', v)}
      />
      <Spacer space={8}/>

      <TextInput
        label="Vehicle Registration number"
        required
        value={values.vrn}
        error={touched.vrn && errors.vrn}
        maxLength={12}
        disabled={!!customerID}
        toUpperCase
        pattern={/[^\w]/g}
        onChange={(v) => setFieldValue('vrn', v)}
      />
      <Spacer space={8}/>

      <TextInput
        label="Mobile Number"
        required
        value={values.mobile}
        error={touched.mobile && errors.mobile}
        maxLength={10}
        disabled={!!customerID}
        pattern={/[^0-9]/g}
        onChange={(v) => setFieldValue('mobile', v)}
      />
      <Spacer space={8}/>
      <Notes/>
      <Spacer space={24}/>

      {
        !!error && (<Alert variant="filled" sx={{margin: '8px 0'}} severity="error">{error}</Alert>)
      }

      <Button loading={loading} onClick={submitForm}>Continue</Button>

      <ConfirmDetails
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        values={values}
        loading={loading}
        idTypeMeta={idTypeMeta}
        handleContinue={handleRegister}
      />
    </Container>
  );
};

export default withStore(NewApplication);
