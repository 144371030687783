import {Box, Container, Grid, SwipeableDrawer, Typography} from '@mui/material';
import styles from '../styles';
import iconConfirm from '../images/iconConfirm.svg';
import Spacer from '../../../UIKit/Spacer';
import Button from '../../../UIKit/Button';
import {idTypeMeta} from '../config';


const DetailRow = ({label, value}) => {
  return (
    <Grid direction="row" container justifyContent="space-between" alignItems="center" sx={{marginBottom: '5px'}}>
      <Typography>{label}</Typography>
      <Typography sx={{fontWeight: '600'}}>{value}</Typography>
    </Grid>
  )
}

const ConfirmDetails = ({values, showConfirm, loading, setShowConfirm, handleContinue}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={showConfirm}
      onOpen={() => setShowConfirm(true)}
      onClose={() => setShowConfirm(false)}
    >
      <Container maxWidth="xs" sx={styles.rootDrawer}>
        <Grid direction="column" container justifyContent="center" alignItems="center">
          <img src={iconConfirm} alt="confirm"/>
          <Spacer space={12}/>
          <Typography sx={styles.drawerTitle}>Review and confirm your details</Typography>
          <Spacer space={12}/>
          <Box sx={styles.drawerConfirmDetails}>
            <DetailRow label="VRN" value={values.vrn}/>
            {
              !!values.idType && (
                <>
                  <DetailRow label="ID Type" value={idTypeMeta[values.idType].label}/>
                  <DetailRow label={idTypeMeta[values.idType].label} value={values.idNumber}/>
                </>
              )
            }
            <DetailRow label="PAN" value={values.pan}/>
            <DetailRow label="Mobile number" value={values.mobile}/>
          </Box>
          <Spacer space={12}/>

          <Grid direction="row" container justifyContent="space-between" alignItems="center">
            <Grid item sx={{width: `calc(50% - 6px)`}}>
              <Button disabled={loading} onClick={() => setShowConfirm(false)} variant='outlined'>Edit details</Button>
            </Grid>
            <Grid item sx={{width: '12px'}}/>
            <Grid item sx={{width: `calc(50% - 6px)`}}>
              <Button loading={loading} onClick={handleContinue}>Continue</Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </SwipeableDrawer>
  )
}

export default ConfirmDetails;