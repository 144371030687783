import React from 'react';
import {Typography} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const DatePicker = ({
  label,
  onChange,
  value,
  error,
  required,
  disabled,
  minDate,
  maxDate,
  format
}) => {
  const hasError = !!error;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        sx={[styles.root, hasError ? styles.rootError : null]}
        disabled={disabled}
        label={label}
        value={value}
        onChange={onChange}
        format={format}
        minDate={minDate}
        maxDate={maxDate}
        openTo="year"
        slotProps={{textField: {required: required}}}
      />
      {
        hasError && (<Typography sx={styles.errorText}>{error}</Typography>)
      }
    </LocalizationProvider>
  );
}

const styles = {
  root: {
    width: '100%',
  },
  rootError: {
    '& .MuiOutlinedInput-notchedOutline ': {
      borderColor: 'red'
    }
  },
  errorText: {
    color: '#d32f2f',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    textAlign: 'left',
    marginTop: '3px',
    marginRight: '14px',
    marginBottom: 0,
    marginLeft: '14px',
  }
}

export default DatePicker