const styles = {
  navbar: {
    backgroundColor: '#FFFFFF',
  },
  offset: (t) => t.mixins.toolbar,
  menuIcon: {
    color: '#047C74',
  },
};

export default styles