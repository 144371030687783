const styles = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '34px'
  },
  typeVehicle: {
    color: '#333333',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0px 16px',
    marginTop: '15px'
  },
  uploadRCText: {
    color: '#333333', fontSize: '10px', marginBottom: '10px'
  }
}

export default styles;