import * as Yup from 'yup';
import {validPAN, validVRN} from '../../utils/yupMethods';
import {alphaNumericOnly, mobileNumberRegex} from '../../utils/validations';

export const idTypeMeta = {
  'drivingLicence': {
    label: 'Driving Licence',
    key: 'drivingLicence',
    maxLength: 20,
    pattern: /[^\w]/g
  },
  'evic': {
    label: 'Voter ID',
    key: 'evic',
    maxLength: 10,
    pattern: /[^\w]/g
  },
  'passport': {
    label: 'Passport',
    key: 'passport',
    maxLength: 8,
    pattern: /[^\w]/g
  },
  'aadhaar': {
    label: 'Aadhaar Card',
    key: 'aadhaar',
    maxLength: 12,
    pattern: /[^0-9]/g
  }
};

export const idTypeMetaMap = {
  DL: 'drivingLicence',
  EVIC: 'evic',
  PP: 'passport',
  AADHAAR: 'aadhaar',
}

export const initialValues = {
  idType: '',
  idNumber: '',
  pan: '',
  vrn: '',
  mobile: '',
};

Yup.addMethod(Yup.string, 'validVRN', validVRN);
Yup.addMethod(Yup.string, 'validPAN', validPAN);
export const validationSchema = Yup.object().shape({
  idType: Yup.string().nullable().required('Please select ID Type'),
  idNumber: Yup.string().nullable().required('Mandetory field!').matches(alphaNumericOnly, 'Please enter valid id number.'),
  pan: Yup.string().nullable().required('Mandatory field!').validPAN(),
  vrn: Yup.string().nullable().required('Mandatory field!').validVRN(),
  mobile: Yup.string().nullable().required('Mandatory field!').matches(mobileNumberRegex, 'Please enter valid mobile number.'),
});