import {Grid, Typography} from '@mui/material';
import iconHelpline from '../images/helpline_icon.svg';

const Contact = () => (
  <Grid container justifyContent="center">
    <Grid item>
      <img style={styles.helpIcon} src={iconHelpline} alt="help"/>
    </Grid>
    <Grid item xs={12}>
      <Typography align="center" sx={styles.contactText}>
        18002100104
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography align="center" sx={styles.mt15}>
        For any support regarding your order, call the above number and provide your Customer ID
      </Typography>
    </Grid>
  </Grid>
)

const styles = {
  helpIcon: {
    marginTop: '40px'
  },
  mt15: {
    marginTop: '15px'
  },
  contactText: {
    marginTop: '15px',
    color: '#004E98',
    fontWeight: '700',
    fontSize: '18px',
  }
}

export default Contact;