import {CircularProgress, Container, Grid} from '@mui/material';

const Landing = () => {
  return (
    <Container maxWidth="xs" sx={styles.root}>
      <Grid direction="column" container justifyContent="center" alignItems="center">
        <CircularProgress color="inherit" />
        <p style={{ fontWeight: 700, fontSize: 16 }}>
          Validating URL...
        </p>
      </Grid>
    </Container>
  )
}

const styles = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '34px'
  },
}

export default Landing;