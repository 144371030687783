import {useEffect, useState} from 'react';
import {Container, LinearProgress} from '@mui/material';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import {format, subYears, parse} from 'date-fns';

import TextInput from '../../UIKit/TextInput';
import Button from '../../UIKit/Button';
import Spacer from '../../UIKit/Spacer';
import DatePicker from '../../UIKit/Datepicker';
import ButtonGroupInput from '../../UIKit/ButtonGroupInput';
import withStore from '../../globalStore/withStore';

import StepsHeader from '../../components/StepsHeader';
import {savePersonalDetails} from '../../api';

import styles from './styles';
import commonStyles from '../../UIKit/commonStyles';
import {emailRegex} from '../../utils/validations';

const initialValues = {
  title: '',
  firstName: '',
  lastName: '',
  gender: '',
  dateOfBirth: null,
  mothersMaidenName: '',
  emailID: ''
};
const now = new Date();
const today = parse(format(now, 'yyyy-MM-dd'), 'yyyy-MM-dd', now);

const PersonalInfo = ({getStore, updateStore}) => {
  const navigate = useNavigate();
  const storeData = getStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const {personalDetails} = storeData
    if(personalDetails){
      if(personalDetails.dateOfBirth){
        personalDetails.dateOfBirth = new Date(personalDetails.dateOfBirth);
      }
      setValues(personalDetails)
    }
  }, [])

  const validationSchema = Yup.object().shape({
    title: Yup.string().nullable().required('Mandatory field!'),
    firstName: Yup.string().nullable().required('Mandatory field!').min(2, 'Minimum 2 characters required!'),
    lastName: Yup.string().nullable().required('Mandatory field!').min(2, 'Minimum 2 characters required!'),
    mothersMaidenName: Yup.string().nullable().required('Mandatory field!').min(2, 'Minimum 2 characters required!'),
    emailID: Yup.string().nullable().required('Mandatory field!').matches(emailRegex, 'Invalid email id'),
    dateOfBirth: Yup.string().nullable().required('Mandatory field!'),
    gender: Yup.string().nullable().required('Mandatory field!'),
  });

  const handleSubmit = async (values) => {
    try{
      setLoading(true)
      const dob = format(values.dateOfBirth, 'yyyy-MM-dd')
      const updatedValues = {...values, dateOfBirth: dob}
      updatedValues.mothersMaidenName = updatedValues.mothersMaidenName.trim()
      await savePersonalDetails(updatedValues)
      updateStore({personalDetails: updatedValues})
      setLoading(false);
      navigate('/delivery-address-info', {replace: true})
    }catch (err){
      setLoading(false);
    }
  };

  const {values, errors, touched, setValues, setFieldValue, submitCount, submitForm} = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const formTouched = submitCount > 0;

  return (
    <Container maxWidth="xs" sx={styles.root}>
      <LinearProgress sx={commonStyles.progressBar} variant="determinate" color="primary" value={60}/>

      <StepsHeader text="Please enter your personal details"/>

      <ButtonGroupInput
        options={['Mr', 'Mrs', 'Ms', 'Miss', 'Dr']}
        gridSize={2}
        onChange={(v) => setFieldValue('title', v)}
        value={values.title}
        error={(formTouched || touched.title) && errors.title}
      />
      <Spacer space={8}/>
      <TextInput
        label="First name"
        required
        error={(formTouched || touched.firstName) && errors.firstName}
        value={values.firstName}
        maxLength={50}
        toUpperCase
        pattern={/[^A-Z]/g}
        onChange={(v) => setFieldValue('firstName', v)}
      />
      <Spacer space={8}/>
      <TextInput
        label="Last name"
        required
        error={(formTouched || touched.lastName) && errors.lastName}
        value={values.lastName}
        maxLength={50}
        toUpperCase
        pattern={/[^A-Z]/g}
        onChange={(v) => setFieldValue('lastName', v)}
      />
      <Spacer space={8}/>

      <ButtonGroupInput
        options={['Male', 'Female', 'Transgender']}
        gridSize={4}
        onChange={(v) => setFieldValue('gender', v)}
        value={values.gender}
        error={(formTouched || touched.gender) && errors.gender}
      />
      <Spacer space={8}/>

      <DatePicker
        label="Date of Birth"
        required
        error={(formTouched || touched.dateOfBirth) && errors.dateOfBirth}
        value={values.dateOfBirth}
        onChange={(v) => setFieldValue('dateOfBirth', v)}
        format={"dd/MM/yyyy"}
        minDate={subYears(today, 100)}
        maxDate={subYears(today, 18)}
      />

      <Spacer space={18}/>

      <TextInput
        label="Mother's maiden name"
        required
        error={(formTouched || touched.mothersMaidenName) && errors.mothersMaidenName}
        value={values.mothersMaidenName}
        maxLength={50}
        toUpperCase
        pattern={/[^A-Z ]/g}
        onChange={(v) => setFieldValue('mothersMaidenName', v.replace(/\s{2,}/g,' '))}
      />
      <Spacer space={8}/>
      <TextInput
        label="Email ID"
        required
        error={(formTouched || touched.emailID) && errors.emailID}
        value={values.emailID}
        maxLength={100}
        pattern={/[^.A-Za-z0-9@\-_]/g}
        onChange={(v) => setFieldValue('emailID', v)}
      />

      <Spacer space={24}/>

      <Button loading={loading} onClick={submitForm}>Continue</Button>
    </Container>
  );
};

export default withStore(PersonalInfo);
