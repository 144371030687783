import {Typography} from '@mui/material';
import iconSuccess from '../images/success.svg';

const title = {
  'new': 'Your order is placed',
  'existing': 'Your order is already placed',
}

const OrderPlaced = ({orderType}) => {
  return (
    <>
      <img
        src={iconSuccess}
        width="110"
        height="110"
        alt="status"
      />

      <Typography variant="h5" style={{textAlign: 'center'}}>
        {title[orderType]}
      </Typography>

      <Typography variant="caption" display="block" style={{textAlign: 'center', marginTop: 8}}>
        You will receive a SMS with the tracking details once your order is confirmed by ICICI Bank.
      </Typography>
    </>
  )
}

export default OrderPlaced;