import {useEffect, useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import queryString from 'query-string';

import Button from '../../UIKit/Button';

import withStore from '../../globalStore/withStore';
import {AMZ_REDIRECT_LINK} from '../../config';

import DispatchInProcess from './subcomponent/DispatchInProcess';
import DispatchComplete from './subcomponent/DispatchComplete';
import CustomerIDView from './subcomponent/CustomerIDView';
import DownloadAppETOLL from './subcomponent/DownloadAppETOLL';
import OrderPlaced from './subcomponent/OrderPlaced';
import Contact from './subcomponent/Contact';
import ReferenceIDView from './subcomponent/ReferenceIDView';
import {txnStatus} from '../../api';

import styles from './styles';

const OrderSummary = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [txnDetails, setTxnDetails] = useState({});
  const { type: orderType, source_app: sourceApp, customer_uid: tpCustomerId } = queryString.parse(location?.search);

  useEffect(() => {
    getTxnDetails();
  }, [])

  const getTxnDetails = async () => {
    try{
      const {amazon_ref, dispatch_id, dispatch_company,} = await txnStatus({customer_id: tpCustomerId});
      setTxnDetails({
        dispatchId: dispatch_id,
        dispatchCompany: dispatch_company,
        referenceID: amazon_ref,
      })
    }catch (err){
      navigate('/failure', { replace: true})
    }
  }

  const redirectToAMZ = () => {
    window.location.href = AMZ_REDIRECT_LINK;
  }

  const {dispatchId, dispatchCompany, referenceID} = txnDetails;

  return (
    <Container maxWidth="xs" sx={styles.root}>
      <Grid container justifyContent="center" alignItems="center">
        <OrderPlaced type={orderType}/>

        <Grid container>
          <Grid item xs={12} sx={{zIndex: 20}}>
            <Card sx={styles.card}>
              <CardContent>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <ReferenceIDView sourceApp={sourceApp} referenceID={referenceID}/>
                  <Grid item xs={12}>
                    <Divider sx={styles.divider}/>
                  </Grid>
                  <Grid item xs={12} sx={{textAlign: 'center'}}>
                    <Typography sx={styles.dispatchStatusTitle}>Dispatch status</Typography>
                    {
                      dispatchId ? (
                        <DispatchComplete dispatchCompany={dispatchCompany} awb={dispatchId}/>
                      ) : <DispatchInProcess/>
                    }
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} style={{zIndex: 10}}>
            <CustomerIDView tpCustomerId={tpCustomerId}/>
          </Grid>

          <DownloadAppETOLL/>
          <Contact/>

          {
            sourceApp === 'amazon' && (
              <Box sx={{paddingTop: '24px', width: '100%'}}>
                <Button onClick={redirectToAMZ}>Return back to Amazon</Button>
              </Box>
            )
          }
        </Grid>
      </Grid>
    </Container>
  )
}

export default withStore(OrderSummary);