import {useRef, useState} from 'react';
import {
  Alert,
  Box,
  Card,
  CardContent, Checkbox, CircularProgress,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {approveTnC, createCustomer, pgOrderID, pgStatus} from '../../api';
import {initPG} from '../../services/razorpay';

import Button from '../../UIKit/Button';
import Spacer from '../../UIKit/Spacer';

import withStore from '../../globalStore/withStore';

import StepsHeader from '../../components/StepsHeader';
import TagCostVRN from '../../components/TagCostVRN';

import PersonalDetails from './subcomponents/PersonalDetails';
import AddressDetails from './subcomponents/AddressDetails';
import PriceDetails from './subcomponents/PriceDetails';

import styles from './styles';
import commonStyles from '../../UIKit/commonStyles';


const ConfirmOrder = ({getStore, updateStore}) => {
  const navigate = useNavigate();
  const storeData = getStore();
  const customerID = useRef(null);
  const [error, setError] = useState('');
  const [loadingTnC, setLoadingTnC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptTnC, setAcceptTnC] = useState(false);

  const handleCheckTnC = async (ev) => {
    const {checked} =  ev.target;
    if(!checked) return;
    try{
      setLoadingTnC(true);
      await approveTnC({
        description: 'Accept Term & condition for amazon issuance'
      })
      setAcceptTnC(checked);
    }catch (err){
      setAcceptTnC(false);
    }finally {
      setLoadingTnC(false);
    }
  }

  const handlePaymentSuccess = async ({razorpay_signature, razorpay_order_id, razorpay_payment_id}) => {
    try{
      await pgStatus({
        orderId: razorpay_order_id,
        paymentId: razorpay_payment_id,
        signature: razorpay_signature
      })
      const {issuanceParams: {utm_source}} = storeData;
      navigate(`/order-summary?type=new&customer_uid=${customerID.current}&source_app=${utm_source?.toLowerCase()}`, {
        replace: true,
      })
    }catch (err){
      const {issuanceParams: {utm_source, utm_appRefNo}} = storeData;
      navigate('/application-failed', {
        state: {appRefNo: utm_appRefNo, sourceApp: utm_source.toLowerCase()},
        replace: true
      })
    }
  }

  const handlePaymentFailed = (err) => {
    setError('Payment failed. Please try again!')
  }

  const confirmDetails = async () => {
    setLoading(true);

    try{
      const {CustomerId} = await createCustomer();
      customerID.current = CustomerId;
      updateStore({customerID: CustomerId})
    }catch (err){
      setLoading(false);
      const newApplicationError = err?.msg;
      navigate('/new-application', {state: {newApplicationError}, replace: true})
      return;
    }

    try{
      const {id: orderID, amount} = await pgOrderID();
      const {issuanceParams, applicationDetails, personalDetails} = storeData;
      initPG({
        amount,
        sourceApp: issuanceParams.utm_source.toLowerCase(),
        orderID,
        name: `${personalDetails.firstName} ${personalDetails.lastName}`,
        email: personalDetails.emailID,
        contact: applicationDetails.mobile,
        handlePaymentSuccess,
        handlePaymentFailed,
      })
      setLoading(false)
    }catch (err){
      setLoading(false)
    }
  }

  const { applicationDetails, addressDetails, personalDetails, vrnDetails, vehicleDetails, tagCost } = storeData;
  return (
    <Container maxWidth="xs" sx={styles.root}>
      <LinearProgress sx={commonStyles.progressBar} variant="determinate" color="primary" value={90}/>
      <StepsHeader text="Order summary" />

      <Grid item xs={12}>
        <Card>
          <TagCostVRN
            fastagPrice={tagCost?.tagTotalAmount}
            rcNumber={applicationDetails?.vrn}
            vehcileCategory={vehicleDetails?.vehicleCategory}
          />
          <Divider sx={{ marginTop: '15px' }} />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={styles.vehicleMakeTitle}>Make</Typography>
                <Typography sx={styles.vehicleMakeData}>
                  {vehicleDetails?.vehicleMake?.MakeDescription}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '8px' }}>
                <Typography sx={styles.vehicleMakeTitle}>Model</Typography>
                <Typography sx={styles.vehicleMakeData}>
                  {vehicleDetails?.vehicleModel?.ModelDescription}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={styles.sectionTitle}>Please review your details below</Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '16px' }}>
        <PersonalDetails personalData={personalDetails} mobileNumber={applicationDetails?.mobile}/>
      </Grid>
      <Divider style={{ width: '100%' }} />
      <Grid item xs={12}>
        <AddressDetails data={addressDetails}/>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '16px' }}>
        <PriceDetails tagCost={tagCost}/>
      </Grid>
      <Spacer space={24}/>

      <Grid container alignItems="center" sx={{ backgroundColor: '#fff' }}>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={1} sx={{padding: '8px 0'}}>
          {
            loadingTnC ? (
              <CircularProgress size={32}/>
            ) : (
              <Checkbox checked={acceptTnC} onChange={handleCheckTnC} color="secondary" />
            )
          }
        </Grid>
        <Grid item xs={11}>
          <Typography
            sx={styles.termsAndConditionsText}
            variant="subtitle2"
          >
            I agree to ICICI Bank's {' '}
            <Box
              component="a"
              href="https://fastaglogin.icicibank.com/CUSTLOGIN/Pages/Common/TermsAndConditions.aspx"
              target="_blank"
              rel="noreferrer"
              sx={styles.termsAndConditionsLink}
            >
              Terms and Conditions
            </Box>
          </Typography>
        </Grid>
      </Grid>
      {
        !!error && (<Alert variant="filled" sx={{margin: '8px 0'}} severity="error">{error}</Alert>)
      }

      <Button loading={loading} disabled={!acceptTnC} onClick={confirmDetails}>Proceed to Pay</Button>
    </Container>
  )
}

export default withStore(ConfirmOrder);