import {
  Grid,
  AppBar as MUIAppBar,
  Toolbar,
  Divider,
  useMediaQuery, useTheme, Box,
} from '@mui/material';

import amazonIconXs from './images/amazon_pay_icon_small.png';
import iciciIconXs from './images/icici_logo_xs.svg';
import amazonIconLg from './images/amazon_pay_icon_large.png';
import iciciIconLg from './images/icici_logo_lg.svg';
import iNETC from './images/netc_logo.svg';
import NuFastagLogoLg from './images/nufastag_logo_lg.svg';
import NuFastagLogoXs from './images/nufastag_logo_xs.svg';

import styles from './styles';
import commonStyles from '../../UIKit/commonStyles';

const AppBar = ({sourceApp}) => {
  const theme = useTheme();

  const isBigThanSM = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <MUIAppBar sx={styles.navbar} position="fixed">
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <Grid container spacing={1}>
                {
                  sourceApp === 'amazon' && (
                    <>
                      <Grid item>
                        <img
                          src={isBigThanSM ? amazonIconLg : amazonIconXs}
                          style={isBigThanSM ? {height: 30, width: 149, marginTop: 5} : {height: 25, width: 40}}
                          alt="amazon"
                        />
                      </Grid>
                      <Grid item sx={isBigThanSM ? commonStyles.px20 : commonStyles.px10}>
                        <Divider orientation="vertical"/>
                      </Grid>
                    </>
                  )
                }
                {
                  sourceApp === 'nufastag' && (
                    <>
                      <Grid item>
                        <img
                          style={{height: isBigThanSM ? 40 : 23, width: isBigThanSM ? 40 : 30}}
                          src={isBigThanSM ? NuFastagLogoLg : NuFastagLogoXs}
                          alt="logo"
                        />
                      </Grid>
                      <Grid item sx={isBigThanSM ? commonStyles.px20 : commonStyles.px10}>
                        <Divider orientation="vertical"/>
                      </Grid>
                    </>
                  )
                }

                <Grid item>
                  <img src={isBigThanSM ? iciciIconLg : iciciIconXs} alt="icici"/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <img src={iNETC} style={isBigThanSM ? {height: 53, width: 276} : {height: 29, width: 155}} alt="netc"/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </MUIAppBar>

      <Box sx={styles.offset}/>
    </>
  );
}

export default AppBar;


