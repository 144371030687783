const env = process.env.REACT_APP_ENV;

export const API_FT_HOST = {
  local: 'http://localhost:5001',
  development: 'https://ftamzapidev.icicibank.com',
  production: 'https://ftamzapiprod.icicibank.com',
}[env]

export const AMZ_REDIRECT_LINK = {
  local: 'https://test.local',
  development: 'https://in-development.amazon.com/hfc/fastag',
  production: 'https://www.amazon.in/hfc/fastag',
}[env]
