import {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography} from '@mui/material';
import styles from '../styles';
import iconExpandLess from '../images/expand-less.svg';
import iconExpandMore from '../images/expand-more.svg';
import iconDelivery from '../images/carbon_delivery.svg';

const PriceDetails = ({tagCost = {}}) => {
  const [expandPriceDetails, setExpandPriceDetails] = useState(false);

  const {tagIssuanceFee, tagSecurityDeposit, tagTotalAmount, tagUploadAmount} = tagCost
  return (
    <Box sx={styles.priceAccordionWrap} >
      <Accordion sx={styles.priceAccordion} expanded={expandPriceDetails} onChange={() => setExpandPriceDetails((f) => !f)}>
        <AccordionSummary sx={styles.accordianSummary}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography sx={styles.priceText}>Total Price</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Typography sx={styles.priceText}>
                ₹{tagTotalAmount}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={5}>
                  <Typography
                    sx={styles.priceBreakupText}
                    color="secondary"
                  >
                    {expandPriceDetails
                      ? 'Hide price breakup'
                      : 'View price breakup'}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <div>
                    {expandPriceDetails ? (
                      <img src={iconExpandLess} alt="shrink" />
                    ) : (
                      <img src={iconExpandMore} alt="expand" />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordianDetails}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.priceBreakUpTitle}>
                    Tag issuance fee
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Typography sx={styles.priceBreakUpData}>
                    ₹{tagIssuanceFee}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={6}>
                  <Typography sx={styles.priceBreakUpTitle}>
                    Tag security deposit
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  <Typography sx={styles.priceBreakUpData}>₹{tagSecurityDeposit}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography sx={styles.priceBreakUpTitle}>
                    Tag Balance amount
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Typography sx={styles.priceBreakUpData}>₹{tagUploadAmount}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{margin: '12px 0'}} />
      <Grid container>
        <Grid item xs={2}>
          <img src={iconDelivery} alt="delivery" />
        </Grid>
        <Grid item xs={10}>
          <Typography style={{ color: '#333333', fontSize: 12 }}>
            Expected delivery within 5-7 working days
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PriceDetails