import {Autocomplete, CircularProgress} from '@mui/material';
import TextInput from '../TextInput';

const styles = {
  autocompleteField: (t) => ({
    '& .MuiAutocomplete-input': {
      color: t.palette.font.primary,
      fontWeight: 700
    },
    '& .MuiAutocomplete-input.Mui-disabled': {
      color: 'rgba(0,0,0,0.02)'
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: 'rgba(0,0,0,0.02)'
    }
  }),
};

const AutocompleteInput = ({
  label,
  required,
  isReadOnly,
  disabled,
  options,
  value,
  onChange,
  loading,
  ...props
}) => {
  return (
    <Autocomplete
      sx={styles.autocompleteField}
      disabled={isReadOnly || disabled}
      options={options}
      value={value}
      onChange={(ev, value) => {
        onChange(value)
      }}
      getOptionLabel={option => option?.label ?? ''}
      renderInput={params => (
        <TextInput
          {...params}
          {...props}
          label={label}
          required={required}
          onChange={() => {}}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {
                  loading && (
                    <CircularProgress size={20}/>
                  )
                }
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default AutocompleteInput;