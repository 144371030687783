import {Box, Container, Grid, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';

import Button from '../../UIKit/Button';
import {AMZ_REDIRECT_LINK} from '../../config';
import iconFail from './failure.svg';

const FailurePage = () => {
  const location = useLocation();
  const { sourceApp, errorMessage = "Something went wrong! Please try again..."} = location?.state ?? {};

  const redirectToAMZ = () => {
    window.location.href = AMZ_REDIRECT_LINK;
  }

  return (
    <Container maxWidth="xs" sx={styles.root}>
      <Grid direction="column" container justifyContent="center" alignItems="center">
        <img src={iconFail} alt="fail"/>
        <Typography sx={styles.headingText}>{errorMessage}</Typography>
      </Grid>

      {
        sourceApp === 'amazon' && (
          <Box sx={{paddingTop: '24px', width: '100%'}}>
            <Button onClick={redirectToAMZ}>Return back to Amazon</Button>
          </Box>
        )
      }
    </Container>
  )
}

const styles = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '34px 24px'
  },
  headingText: {
    marginTop: '24px',
    fontWeight: '800',
    fontSize: 20,
    textAlign: 'center',
  }
}

export default FailurePage