import {IconButton} from '@mui/material';
import iconFileCopy from './file_copy.svg';
import iconFileCopyWhite from './file_copy_white.svg';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const CopyClipboardButton = ({text, size = 16, color }) => {
  return (
    <CopyToClipboard text={text}>
      <IconButton aria-label="copy" sx={{marginLeft: '2px'}}>
        <img width={size} height={size} src={color === 'white' ? iconFileCopyWhite : iconFileCopy} alt="filecopy"/>
      </IconButton>
    </CopyToClipboard>
  )
}

export default CopyClipboardButton;