
export const secondsToHHMMSS = (value, noHour = false) => {
  let hours, minutes, seconds = value;
  if(!noHour){
    hours = Math.floor(seconds / 3600);
    seconds %= 3600;
  }

  minutes = Math.floor(seconds / 60);
  seconds %= 60;

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  if(noHour){
    return `${minutes}:${seconds}`
  }

  return `${hours}:${minutes}:${seconds}`
}