export const mobileNumberRegex = /^[6-9]\d{9}$/; // starts with 6 - 9, with length 10
export const selectedCharsWithSpaces = /^[A-Za-z&().,\-'"\d]+[A-Za-z\d&().,\-'" ]*[A-Za-z\d&().,\-'"]*$/;
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
export const evicRegex = /^([A-Z]){3}(\d){7}?$/;
export const ppRegex = /^[A-Z\d]{12,20}$/;
export const aadhaarRegex = /^[2-9]\d{3}\d{4}\d{4}$/;
export const serialRegex = /^\d{6}-\d{3}-\d{7}$/;


export const numericOnly = /^\d*$/;
export const alphaNumericOnly = /^[A-Za-z\d]*$/;
export const alphaWithSpacesOnly = /^[A-Za-z]+[A-Za-z ]*[A-Za-z]*$/;
export const addressRegex = /^[\dA-Za-z ]*$/;

export const sixDigitRegex = /^\d{6}$/;
export const fourDigitRegex = /^\d{4}$/;