import {useEffect, useRef, useState} from 'react';

const useCountdown = ({time, reset, onComplete}) => {
  const [start, setStart] = useState(false)
  const [countdown, setCountdown] = useState(null)
  const interval = useRef(null);

  useEffect(() => {
    return () => {
      clearInterval(interval.current)
    }
  }, [])

  useEffect(() => {
    getCountdown();
  }, [reset])

  useEffect(() => {
    if(countdown && countdown <= 0){
      clearInterval(interval.current)
      setStart(false);
      !!onComplete && onComplete();
    }
  }, [countdown])

  useEffect(() => {
    if(start){
      startCountdown();
    }
  }, [start])

  const startCountdown = () => {
    interval.current = setInterval(() => {
      setCountdown((c) => c - 1)
    }, 1000)
  }

  const getCountdown = () => {
    if(isNaN(time)){
      const t = time.split(':');
      const hours = Number(t[0]);
      const minutes = Number(t[1]);
      const seconds = Number(t[2]);
      const secondsPending = (hours * 60 * 60) + (minutes * 60) + seconds;
      if(secondsPending <= 0) return;
      setCountdown(secondsPending)
    }else{
      if(time <= 0) return;
      setCountdown(time)
    }
    setTimeout(() => setStart(true), 600)
  }

  return {countdown}
}

export default useCountdown;