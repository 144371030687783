import React, {forwardRef} from 'react';
import { Grid, TextField } from '@mui/material';

const TextInput = forwardRef(({
  id,
  label,
  type,
  onChange,
  error,
  disabled,
  value,
  pattern,
  required,
  maxLength,
  toUpperCase,
  InputProps,
  InputLabelProps,
  ...props
}, ref) => {

  const handleOnChange = (ev) => {
    let v = ev.target.value;
    if(maxLength){
      v = v.substring(0, maxLength)
    }
    if(toUpperCase){
      v = v.toUpperCase()
    }
    if(pattern){
      v = v.replace(pattern, '')
    }
    onChange(v)
  }

  return (
    <Grid xs={12} item>
      <TextField
        {...props}
        select={false}
        id={id}
        label={label}
        type={type}
        onChange={handleOnChange}
        error={!!error}
        disabled={disabled}
        helperText={error}
        value={value}
        required={required}
        variant="outlined"
        fullWidth
        InputProps={{...InputProps, sx: styles.textFieldInput}}
        sx={styles.textField}
        ref={ref}
        autoComplete="off"
      />
    </Grid>
  )
})


const styles = {
  textField: {
    marginBottom: '10px',
    fontWeight: 700
  },
  textFieldInput: (t) => ({
    color: t.palette.font.primary,
    fontWeight: 700
  }),
}

export default TextInput;
