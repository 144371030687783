import React from 'react';
import {Button as MUIButton, Typography, CircularProgress} from '@mui/material';

const styles = {
  root: (t) => ({
    textTransform: 'inherit',
    boxShadow: 'none',
    fontSize: 14,
    background: t.palette.primary.mainGradient,
    '&.MuiButton-outlinedPrimary': {
      background: 'transparent',
    },
    '&.Mui-disabled': {background: '#f9d3a1'},
  }),
};

const Button = ({
  children,
  onClick,
  disabled,
  type,
  loading,
  startIcon,
  sx,
  variant,
  color,
  loadingMessage,
  loaderColor,
  ...props
}) => {

  return (
    <MUIButton
      {...props}
      onClick={() => !!onClick && onClick()}
      fullWidth
      type={type}
      variant={variant ?? 'contained'}
      color={color ?? 'primary'}
      disabled={disabled || loading}
      startIcon={startIcon}
      sx={[variant === 'text' ? null : styles.root, sx, {height: '48px'}]}
    >
      {
        loading && (
          <>
            <CircularProgress size={24} style={{color: loaderColor ?? 'white'}}/>
            {loadingMessage && <Typography>{loadingMessage}</Typography>}
          </>
        )}
      <Typography sx={{fontWeight: '600', color: variant === 'outlined' ? 'primary' : 'white'}}>
        {children && !loading ? children : null}
      </Typography>
    </MUIButton>
  );
}

export default Button