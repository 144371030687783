const styles = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '34px'
  },
  sectionTitle: {
    marginTop: '25px',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  vehicleNumberText: {
    fontWeight: 'bold'
  },
  vehicleMakeTitle: {
    fontSize: '12px',
    color: '#666666'
  },
  vehicleMakeData: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#333333'
  },
  priceBreakUpTitle: {
    marginTop: '15px',
    fontSize: '12px',
    color: '#666666'
  },
  priceBreakUpData: {
    marginTop: '10px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#333333'
  },
  priceAccordionWrap: (t) => ({
    padding: '12px',
    backgroundColor: t.palette.secondary.lighter,
  }),
  priceAccordion: (t) => ({
    backgroundColor: t.palette.secondary.lighter,
    root: `-webkit-box-shadow: none;
           -moz-box-shadow: none;
           box-shadow: none;`
  }),
  priceText: {
    color: 'black',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  priceBreakupText: (t) => ({
    color: t.palette.secondary,
    fontSize: '12px'
  }),
  accordian: {
    root: `-webkit-box-shadow: none;
                 -moz-box-shadow: none;
                 box-shadow: none;`
  },
  accordianSummary: {
    padding: 0
  },
  accordianDetails: {
    paddingRight: 0,
    paddingLeft: 0
  },
  termsAndConditionsText: {
    color: '#333',
    paddingLeft: '15px',
    paddingRight: '5px',
    fontSize: '12px'
  },
  termsAndConditionsLink: (t) => ({
    color: t.palette.secondary,
    textDecoration: 'none',
    fontWeight: 600
  })
}

export default styles;