import {Container, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';

import TrustIcon1 from './images/infoIconRupee.svg';
import TrustIcon2 from './images/infoIconPeople.svg';
import TrustIcon3 from './images/infoIconVehicle.svg';
import commonStyles from '../../UIKit/commonStyles';

const benefits = [
  {
    key: 1,
    text: 'Toll, Fuel & Parking payments',
    img: TrustIcon1,
  },
  {
    key: 2,
    text: '8.5M+ happy customers',
    img: TrustIcon2,
  },
  {
    key: 3,
    text: 'Free home \ndelivery',
    img: TrustIcon3,
  }
]

const AmzIssBenefit = ({isBiggerThanSm, img, text}) => (
  <Grid item xs={4}>
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item xs={12} md={3} sx={isBiggerThanSm ? commonStyles.textRight : commonStyles.textCenter}>
        <img src={img} height={28} alt="icon"/>
      </Grid>
      <Grid item xs={12} md={9} sx={isBiggerThanSm ? commonStyles.textLeft : commonStyles.textCenter}>
        <Typography sx={styles.trustIconText}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
)

const AmazonIssuanceInfo = () => {
  const theme = useTheme();

  const isBiggerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Grid item sx={commonStyles.textCenter}>
        <Typography sx={styles.title} variant="h4">Buy Amazon Pay ICICI Bank FASTag</Typography>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth={isBiggerThanSm ? 'md' : 'xs'} fixed sx={commonStyles.mb10}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={2}
            sx={isBiggerThanSm ? styles.wrapContainerCenter : commonStyles.p0}
          >
            {benefits.map((b) => <AmzIssBenefit {...b} isBiggerThanSm={isBiggerThanSm}/>)}
          </Grid>
        </Container>
      </Grid>
    </>
  )
}

const styles = {
  offset: (t) => t.mixins.toolbar,
  title: {
    margin: '20px',
    marginLeft: '16px'
  },
  wrapContainerCenter: {
    padding: '0px 198px',
    transform: 'translateX(20px)'
  },
  poweredBy: {
    textAlign: 'center',
    fontSize: '10px',
    marginBottom: '10px',
  },
  trustIconText: (t) => ({
    color: t.palette.font.main,
    fontSize: '12px',
  }),
  timerContainer: {
    textAlign: 'center',
    backgroundColor: '#FFF4E4',
    color: '#333333',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
}

export default AmazonIssuanceInfo