import {Grid, Typography} from '@mui/material';

const StepsHeader = ({text}) => {
  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          color: '#333333',
          fontSize: '15px',
          fontWeight: 700,
          marginBottom: '15px',
          marginTop: '15px',
        }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default StepsHeader;
