export const setStorageBulk = (items) => {
  const keys = Object.keys(items);
  keys.forEach((k) => {
    const v = items[k];
    sessionStorage.setItem(k, typeof v === 'string' ? v : JSON.stringify(v))
  })
}

export const setStorageItem = (key, value) => {
  sessionStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value))
}

export const isExistItemInStorage = (key) => {
  const value = sessionStorage.getItem(key)
  return !!value;
}

export const getStorageItem = (key, isJSON) => {
  const value = sessionStorage.getItem(key)
  try{
    return isJSON ? JSON.parse(value) : value;
  }catch (e){
    return value;
  }
}

export const clearStorage = () => {
  sessionStorage.clear()
}