import React, {forwardRef} from 'react';
import {Grid, MenuItem, TextField} from '@mui/material';

const SelectInput = forwardRef(({
  id,
  label,
  type,
  onChange,
  error,
  disabled,
  value,
  options,
  required,
  InputProps,
  InputLabelProps,
  ...props
}, ref) => {
  return (
    <Grid xs={12} item>
      <TextField
        {...props}
        select
        id={id}
        label={label}
        type={type}
        onChange={(ev) => onChange(ev.target.value)}
        error={!!error}
        disabled={disabled}
        helperText={error}
        value={value}
        required={required}
        variant="outlined"
        fullWidth
        InputLabelProps={{...InputLabelProps, sx: styles.textInputLabel}}
        InputProps={{...InputProps, sx: styles.textFieldInput}}
        sx={styles.textField}
        ref={ref}
      >
        {options.map(item => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  )
})


const styles = {
  textField: {
    marginBottom: '10px',
    fontSize: '16px',
    fontWeight: 700
  },
  textFieldInput: (t) => ({
    color: t.palette.font.primary,
    fontSize: '16px',
    fontWeight: 700
  }),
  textInputLabel: {
    fontSize: '16px'
  }
}

export default SelectInput;
