const styles = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '34px'
  },
  card: (t) => ({
    zIndex: 20,
    background: t.palette.primary.light,
    marginTop: '20px',
    fontSize: '12px',
    borderRadius: '10px',
    boxShadow: 'none',
  }),
  dispatchStatusTitle: (t) => ({
    color: t.palette.font.main,
    paddingTop: '10px',
    paddingBottom: '5px',
    opacity: 0.5,
    fontSize: '12px',
  }),
  divider: (t) => ({
    marginBottom: '10px',
    marginTop: '10px',
    backgroundColor: t.palette.font.main
  }),
  returnToHomeAppBar: {
    top: 'auto',
    backgroundColor: '#FFFFFF',
    paddingTop: '4px',
    paddingBottom: '4px',
    bottom: 0,
  },
}

export default styles;