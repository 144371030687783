import {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import styles from '../styles';
import iconEdit from '../images/edit.svg';
import iconExpandLess from '../images/expand-less.svg';
import iconExpandMore from '../images/expand-more.svg';
import iconHome from '../images/home.svg';

const AddressDetails = ({data = {}}) => {
  const [expandAddressDetails, setExpandAddressDetails] = useState(false);
  const navigate = useNavigate()

  const goToAddressForm = () => {
    navigate('/delivery-address-info', {replace: true})
  }

  const {addressLine1, addressLine2, landmark, pincode, city, state } = data;
  return (
    <Accordion sx={styles.accordian} expanded={expandAddressDetails} onChange={() => setExpandAddressDetails((f) => !f)}>
      <AccordionSummary sx={styles.accordianSummary}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <img src={iconHome} alt="home" />
          </Grid>
          <Grid item xs={8}>
            <Typography>Delivery details</Typography>
          </Grid>
          <Grid item xs={1}>
            <img onClick={goToAddressForm} src={iconEdit} alt="edit" />
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            {
              expandAddressDetails ? (
                <img src={iconExpandLess} alt="expand more" />
              ) : (
                <img src={iconExpandMore} alt="expand more" />
              )
            }
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordianDetails}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={styles.vehicleMakeTitle}>Address</Typography>
            <Typography sx={styles.vehicleMakeData}>
              {addressLine1}
              {', '}
              {addressLine2}
              {', '}
              {landmark}
              {', '}
              {city}
              {', '}
              {state}
              {', '}
              {pincode}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default AddressDetails