import {Card, CardContent, Chip, Grid, Typography} from '@mui/material';
import eTollScreenshotIMG from '../images/etoll_screenshot.svg';
import iconICICISquare from '../images/icici_icon_sq_bg.svg';
import logoGooglePlay from '../images/google_play_logo.svg';
import Spacer from '../../../UIKit/Spacer';

const DownloadAppETOLL = () => (
  <Grid>
    <Card sx={styles.etollCard}>
      <CardContent>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <img style={styles.etollApp} src={eTollScreenshotIMG} alt="recharge"/>
          </Grid>
          <Grid item>
            <Typography sx={styles.etollCardTitleText}>
              Recharge & manage your FASTag/s with No.1 FASTag app
            </Typography>
          </Grid>
          <Grid item>
            <Chip sx={styles.etollCardChip} label="Use your customer ID to login"/>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center" sx={styles.mt20} spacing={2}>
              <Grid item>
                <img src={iconICICISquare} alt="icici"/>
              </Grid>
              <Grid item>
                <Spacer space={7} inline/>
              </Grid>
              <Grid>
                <Typography variant="h4">
                  eToll by <br/> ICICI Bank
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://play.google.com/store/apps/details?id=com.icicibank.fastag"
            >
              <img style={{marginTop: 18}} src={logoGooglePlay} alt="playstore"/>
            </a>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
)


const styles = {
  etollCard: (t) => ({
    marginTop: '50px',
    backgroundColor: t.palette.secondary.lighter,
    borderRadius: '10px',
    boxShadow: 'none',
    overflow: 'visible',
  }),
  etollCardTitleText: (t) => ({
    color: t.palette.font.main,
    fontSize: '16px',
    fontWeight: '700',
    textAlign: 'center',
  }),
  etollCardChip: (t) => ({
    backgroundColor: t.palette.msgChip.main,
    marginTop: '20px',
  }),
  mt20: {
    marginTop: '20px'
  },
  etollApp: {
    marginTop: '-50px'
  }
}

export default DownloadAppETOLL