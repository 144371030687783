import {useState, useEffect} from 'react';
import {Container, Grid, LinearProgress} from '@mui/material';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import withStore from '../../globalStore/withStore';

import TextInput from '../../UIKit/TextInput';
import Button from '../../UIKit/Button';
import Spacer from '../../UIKit/Spacer';
import AutocompleteInput from '../../UIKit/AutocompleteInput';

import StepsHeader from '../../components/StepsHeader';
import pinCodeDetails from '../../api/pincode';

import styles from './styles';
import {getStateDataByName, getStateListOptions} from '../../utils/stateData';
import {saveDeliveryAddressDetails} from '../../api';
import commonStyles from '../../UIKit/commonStyles';

const initialValues = {
  addressLine1: '',
  addressLine2: '',
  landmark: '',
  city: '',
  state: '',
  pincode: ''
};

const stateList = getStateListOptions();

const DeliveryAddressInfo = ({getStore, updateStore}) => {
  const navigate = useNavigate();
  const storeData = getStore();
  const [loading, setLoading] = useState(false);
  const [pinCodeSuccess, setPinCodeSuccess] = useState(true);

  const validationSchema = Yup.object().shape({
    addressLine1: Yup.string().nullable().required('Mandatory field!').min(5, 'Minimum 5 characters required!'),
    addressLine2: Yup.string().nullable().required('Mandatory field!').min(5, 'Minimum 5 characters required!'),
    landmark: Yup.string().nullable().required('Mandatory field!').min(5, 'Minimum 5 characters required!'),
    pincode: Yup.string().nullable().required('Mandatory field!'),
    city: Yup.string().nullable().required('Mandatory field!'),
    state: Yup.object().nullable().required('Mandatory field!'),
  });

  useEffect(() => {
    const {addressDetails} = storeData;
    if(addressDetails){
      if(addressDetails.stateRaw){
        addressDetails.state = addressDetails.stateRaw;
      }
      setValues(addressDetails)
      if(addressDetails.pincode && !addressDetails.state){
        handlePinCodeInput(addressDetails.pincode)
      }
    }
  // eslint-disable-next-line
  }, [])

  const handlePinCodeInput = async (pincode) => {
    if(pincode.length !== 6) return;
    try{
      const response = await pinCodeDetails({pincode})
      if (response.length > 0) {
        let {district, state} = response[0];
        if (district.includes('(')) {
          district = district.split('(')[0];
        }
        const stateData = getStateDataByName(state);
        await setFieldValue('city', district)
        await setFieldValue('state', {label: stateData.name, value: stateData.code, })
      }else{
        setFieldError('pincode', 'Please enter valid pincode')
        await setFieldValue('city', '')
        await setFieldValue('state', null)
      }
      setPinCodeSuccess(true)
    }catch (err){
      await setFieldValue('city', '')
      await setFieldValue('state', null)
      if(err?.data?.detail?.length > 0){
        setFieldError('pincode', 'Please enter valid pincode')
      }else{
        setPinCodeSuccess(false)
      }
    }
  }
  const handleSubmit = async (values) => {
    try{
      setLoading(true)
      const stateRaw = {...values.state};
      values.state = stateRaw.value;
      await saveDeliveryAddressDetails(values)
      values.stateRaw = stateRaw
      updateStore({addressDetails: values})
      setLoading(false)
      navigate('/confirm-order', {replace: true})
    }catch (err){
      setLoading(false)
    }
  };


  const {values, errors, touched, submitCount, setValues, setFieldValue, setFieldError, submitForm} = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const formTouched = submitCount > 0;

  return (
    <Container maxWidth="xs" sx={styles.root}>
      <LinearProgress sx={commonStyles.progressBar} variant="determinate" color="primary" value={80}/>
      <StepsHeader text="What is your delivery address?"/>
      <TextInput
        label="Address Line 1"
        required
        error={(formTouched || touched.addressLine1) && errors.addressLine1}
        value={values.addressLine1}
        maxLength={50}
        onChange={(v) => setFieldValue('addressLine1', v)}
      />
      <Spacer space={8}/>
      <TextInput
        label="Address Line 2"
        required
        error={(formTouched || touched.addressLine2) && errors.addressLine2}
        value={values.addressLine2}
        maxLength={50}
        onChange={(v) => setFieldValue('addressLine2', v)}
      />
      <Spacer space={8}/>
      <TextInput
        label="Landmark"
        required
        error={(formTouched || touched.landmark) && errors.landmark}
        value={values.landmark}
        maxLength={50}
        onChange={(v) => setFieldValue('landmark', v)}
      />
      <Spacer space={8}/>
      <Grid container spacing={1}>
        <Grid item xs={6} sx={{ paddingTop: 0 }}>
          <TextInput
            label="Pincode"
            required
            error={(formTouched || touched.pincode) && errors.pincode}
            value={values.pincode}
            maxLength={6}
            pattern={/[^\d]/g}
            onChange={(v) => {
              setFieldValue('pincode', v);
              handlePinCodeInput(v)
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ paddingTop: 0 }}>
          <TextInput
            label="City"
            required
            disabled={pinCodeSuccess}
            error={formTouched && errors.city}
            value={values.city}
            onChange={(v) => setFieldValue('city', v)}
          />
        </Grid>
      </Grid>
      <Spacer space={8}/>
      <AutocompleteInput
        label="State"
        required
        disabled={pinCodeSuccess}
        error={formTouched && errors.state}
        value={values.state}
        options={stateList}
        onChange={(v) => setFieldValue('state', v)}
      />

      <Spacer space={24}/>

      <Button loading={loading} onClick={submitForm}>Continue</Button>
    </Container>
  );
};

export default withStore(DeliveryAddressInfo);
