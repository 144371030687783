import {Grid, Typography} from '@mui/material';
import {secondsToHHMMSS} from '../../utils/time';
import useCountdown from '../../hooks/useCountdown';
import {differenceInSeconds} from 'date-fns';

const SessionTimeout = ({sessionTime}) => {
  const diff = differenceInSeconds(new Date(sessionTime), new Date())

  const {countdown} = useCountdown({time: diff, reset: sessionTime})

  if(countdown === null){
    return null;
  }

  return (
    <Grid sx={styles.timerContainer}>
      {
        countdown <= 0 ? (
          <Typography>Link expired</Typography>
        ) : (
          <Typography>
            Your session will expire in {secondsToHHMMSS(countdown)}
          </Typography>
        )
      }
    </Grid>
  )
}

const styles = {
  timerContainer: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#FFF4E4',
    color: '#333333',
    paddingTop: '4px',
    paddingBottom: '4px',
  }
}

export default SessionTimeout