import {useContext} from 'react';
import {GlobalStoreContext} from './index';

const withStore = (RouteComponent) => {
  return (props) => {
    const {getStore, updateStore, clearStore} = useContext(GlobalStoreContext);
    return <RouteComponent {...props} getStore={getStore} updateStore={updateStore} clearStore={clearStore}/>
  }
}

export default withStore