import { Modal, Box, Typography } from '@mui/material';
import maintenanceIcon from '../../assets/maintenance.svg';
import Spacer from '../../UIKit/Spacer';

const MaintenancePopup = ({open, handleClose} ) => {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box sx={styles.container}>
          <img src={maintenanceIcon} width={50} alt='maintenanceIcon' />
          <Spacer space={12}/>
          <Typography  textAlign='center' variant='h6' sx={{fontWeight: 600}}>
            Service Temporarily Unavailable
          </Typography>
          <Spacer space={4} />
          <Typography  textAlign='center'>
            Our systems are undergoing maintenance to ensure you get the best experience. Apologies for any inconvenience caused. We will be back online shortly.
          </Typography>
        </Box>
      </Modal>
    );
};

export default MaintenancePopup;

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4 ,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
};