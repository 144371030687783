import {Grid, Typography} from '@mui/material';
import CopyClipboardButton from '../../../components/CopyClipboardButton';

const DispatchComplete = ({dispatchCompany, awb, }) => {

  const processTrackingURL = () => {
    let url = '';
    switch (dispatchCompany) {
      case 'BLUEDART':
        url = 'https://www.bluedart.com/tracking';
        break;
      case 'SPEEDPOST':
        url = 'https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx';
        break;
      case 'DELHIVERY':
        url = 'https://www.delhivery.com/';
        break;
      default:
        url = '';
    }
    return url;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={styles.dispatchStatusText} variant="h6">
          DISPATCHED
        </Typography>
        <Typography sx={styles.dispatchIdText}>
          AWB no. {awb}
          <CopyClipboardButton text={'2398423949'} size={12}/>
        </Typography>
        <Typography sx={styles.trackYourDeliveryLabel} variant="h5">
          Track your delivery status on {dispatchCompany}
        </Typography>

        <Typography sx={styles.trackYourDeliveryTitle}>
          <a target="_blank" rel="noreferrer" href={processTrackingURL(dispatchCompany)}>
            CLICK TO TRACK
          </a>
        </Typography>
      </Grid>
    </Grid>
  )
}

const styles = {
  dispatchStatusText: (t) => ({
    color: t.palette.font.main,
    fontSize: 14,
    letterSpacing: 0.6,
    fontWeight: 'bold',
    width: '100%',
  }),
  dispatchIdText: (t) => ({
    color: t.palette.font.main,
    fontSize: 12,
    fontWeight: 700,
    width: '100%',
  }),
  trackYourDeliveryLabel: (t) => ({
    color: t.palette.font.main,
    opacity: 0.5,
    paddingTop: '12px',
    fontSize: '12px',
    width: '100%',
  }),
  trackYourDeliveryTitle: (t) => ({
    color: t.palette.font.main,
    fontWeight: '700',
    fontSize: '12px',
    width: '100%',
  }),
}

export default DispatchComplete;