import axios from 'axios';
import {API_FT_HOST} from '../config';

const ftInterface = axios.create({baseURL: API_FT_HOST});

ftInterface.interceptors.request.use(
  (request) => {
    const bearerToken = sessionStorage.getItem('token');
    if (bearerToken) {
      request.headers.Authorization = `bearer ${bearerToken}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

export const apiFT = (url, type, payload, config) => {
  if(!ftInterface) return null;
  return new Promise((resolve, reject) => {
    const ftInterfaceParams = {
      method: type,
      url,
      config,
    };
    if (type.toLowerCase() === 'get') {
      ftInterfaceParams.params = payload;
    } else {
      ftInterfaceParams.data = payload;
    }
    ftInterface(ftInterfaceParams)
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error?.response?.status === 429) {
          const tooManyReq = new CustomEvent('tooManyRequests', {detail: error.response.data})
          window.dispatchEvent(tooManyReq);
        }
        reject(error?.response?.data)
      });
  });
};