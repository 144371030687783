import {ButtonGroup, Button, Typography, Box} from '@mui/material';

const ButtonGroupInput = ({value, options, onChange, error, disabled}) => {
  const hasError = !!error;
  return (
    <Box sx={styles.root}>
      <ButtonGroup
        variant="outlined"
        color="primary"
        aria-label="vertical outlined primary button group"
        fullWidth
        sx={[styles.buttonGroup, hasError ? styles.buttonGroupErr : null]}
      >
        {options.map((d) => (
          <Button
            key={d}
            disabled={disabled}
            variant={d === value ? 'contained' : 'text'}
            color={d === value ? 'secondary' : 'primary'}
            sx={d === value ? styles.buttonSelected : styles.buttonUnselected}
            onClick={() => onChange(d)}
          >
            <Typography sx={d === value ? styles.buttonTextSelected : styles.buttonTextUnselected}>
              {d}
            </Typography>
          </Button>
        ))}
      </ButtonGroup>
      {
        hasError && (<Typography sx={styles.errorText}>{error}</Typography>)
      }
    </Box>
  )
};

const styles = {
  root: {
    marginBottom: '10px'
  },
  buttonGroup: (t) => ({
    '& button': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: t.palette.border.main,
    },
  }),
  buttonGroupErr: (t) => ({
    '& button': {
      borderColor: '#d32f2f',
    },
  }),
  buttonSelected: (t) => ({
    textTransform: 'none',
    backgroundColor: t.palette.primary,
    height: '40px',
  }),
  buttonTextSelected: (t) => ({
    textTransform: 'none',
    color: t.palette.font.inputBtnText,
  }),
  buttonUnselected: {
    textTransform: 'none',
    backgroundColor: 'white',
    height: '40px',
  },
  buttonTextUnselected: (t) => ({
    textTransform: 'none',
    color: t.palette.font.primary,
  }),
  errorText: {
    color: '#d32f2f',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    textAlign: 'left',
    marginTop: '3px',
    marginRight: '14px',
    marginBottom: 0,
    marginLeft: '14px',
  }
}

export default ButtonGroupInput;
