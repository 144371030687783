import React, { useState, useRef } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

// Styled components using MUI's styled API
const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const Label = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const UploadArea = styled(Box)(({ theme, error }) => ({
  height: 108,
  overflow: "hidden",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: error ? theme.palette.error.main : "rgba(229, 229, 229, 0.8)",
  borderRadius: 8,
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  position: "relative",
  transition: "border-color 0.3s ease-in-out", // Added smooth transition for border color
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const CameraIcon = styled(IconButton)(({ theme }) => ({
  fontSize: 40,
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.secondary.main,
  height: 40,
  width: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  transition: "background-color 0.3s ease", // Added transition for hover effect
  "&:hover": {
    backgroundColor: theme.palette.grey[300], // Hover effect
  },
}));

const BackgroundImage = styled("img")({
  position: "absolute",
  width: "100%",
  objectFit: "contain",
});

const HiddenInput = styled("input")({
  display: "none",
});

const HelpText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const FileUpload = ({
  label,
  retakeText,
  innerLabel,
  helpText,
  style,
  value,
  onChange,
  error,
}) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        onChange({ uri: base64data, file });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = (event) => {
    // Prevent further propagation of the event
    event.stopPropagation();
    
    // Trigger file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Root style={style}>
      {label && (
        <Label variant="body1" component="div">
          {label}
        </Label>
      )}
      <UploadArea
        error={error} // Pass error prop for conditional styling
        onClick={handleClick}
      >
        {value && <BackgroundImage src={value.uri} alt="Captured" />}
        {!value ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <CameraIcon onClick={handleClick}>
              <svg
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20.2776" cy="20.7734" r="20" fill="#DDE8FA" />
                <g clipPath="url(#clip0_17485_12599)">
                  <path
                    d="M17.2776 10.7734H23.2776L25.2776 12.7734H29.2776C29.5428 12.7734 29.7972 12.8788 29.9847 13.0663C30.1722 13.2539 30.2776 13.5082 30.2776 13.7734V27.7734C30.2776 28.0387 30.1722 28.293 29.9847 28.4805C29.7972 28.6681 29.5428 28.7734 29.2776 28.7734H11.2776C11.0124 28.7734 10.758 28.6681 10.5705 28.4805C10.3829 28.293 10.2776 28.0387 10.2776 27.7734V13.7734C10.2776 13.5082 10.3829 13.2539 10.5705 13.0663C10.758 12.8788 11.0124 12.7734 11.2776 12.7734H15.2776L17.2776 10.7734ZM20.2776 26.7734C21.8689 26.7734 23.395 26.1413 24.5202 25.0161C25.6454 23.8909 26.2776 22.3647 26.2776 20.7734C26.2776 19.1821 25.6454 17.656 24.5202 16.5308C23.395 15.4056 21.8689 14.7734 20.2776 14.7734C18.6863 14.7734 17.1602 15.4056 16.0349 16.5308C14.9097 17.656 14.2776 19.1821 14.2776 20.7734C14.2776 22.3647 14.9097 23.8909 16.0349 25.0161C17.1602 26.1413 18.6863 26.7734 20.2776 26.7734ZM20.2776 24.7734C19.2167 24.7734 18.1993 24.352 17.4492 23.6019C16.699 22.8517 16.2776 21.8343 16.2776 20.7734C16.2776 19.7126 16.699 18.6952 17.4492 17.945C18.1993 17.1949 19.2167 16.7734 20.2776 16.7734C21.3385 16.7734 22.3559 17.1949 23.106 17.945C23.8562 18.6952 24.2776 19.7126 24.2776 20.7734C24.2776 21.8343 23.8562 22.8517 23.106 23.6019C22.3559 24.352 21.3385 24.7734 20.2776 24.7734Z"
                    fill="#004E98"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_17485_12599">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(8.27759 7.77344)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </CameraIcon>
            <Typography variant="body2" component="div">
              {innerLabel}
            </Typography>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick} // Ensure single trigger
          >
            {retakeText}
          </Button>
        )}
      </UploadArea>
      {helpText && (
        <HelpText variant="caption" component="div">
          {helpText}
        </HelpText>
      )}
      {error && (
        <ErrorMessage variant="body2" component="div">
          <div>{error}</div>
        </ErrorMessage>
      )}
      <HiddenInput
        ref={fileInputRef}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleFileChange}
      />
    </Root>
  );
};

export default FileUpload;
