import React from 'react';
import {Chip, CardHeader, Grid, Typography, CircularProgress} from '@mui/material';
import commonStyles from '../../UIKit/commonStyles';

import carColored from './images/carcolored.svg';

const styles = {
  img: {
    objectFit: 'contain'
  },
  chip: {
    backgroundColor: '#EAF5FF'
  },
  headerAction: {
    paddingBottom: 0,
    '& .MuiCardHeader-action': {
      flex: 'none',
      alignSelf: 'auto',
      marginTop: 0,
      marginRight: 0,
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#333',
      fontSize: 14,
    },
  },
};

const TagCostVRN = ({fastagPrice, rcNumber, vehcileCategory, loading}) => {
  return (
    <CardHeader
      avatar={<img src={carColored} width="48" height="48" style={styles.img}  alt="car"/>}
      action={(
        <Grid container>
          <Grid item xs={12} style={commonStyles.textLeft}>
            {
              vehcileCategory && (
                <Chip
                  sx={styles.chip}
                  label={(
                    <Typography sx={{fontSize: '12px'}} color="secondary">
                      {`${vehcileCategory.slice(0, 1).toUpperCase()}${vehcileCategory.slice(1)}`}
                    </Typography>
                  )}
                />
              )
            }
            {
              loading ? (
                <CircularProgress color="secondary" size={20}/>
              ) : (
                <Typography sx={{color: '#333', fontSize: '16px', fontWeight: 700}}>
                  &#8377;{fastagPrice}
                </Typography>
              )
            }
          </Grid>
        </Grid>
      )}

      title={(
        <Typography sx={{
            color: '#333',
            fontSize: '18px',
            fontWeight: 700,
          }}
        >
          {' '}
          {rcNumber}
        </Typography>
      )}
      subheader="Car / Jeep / Van"
      sx={styles.headerAction}
    />
  );
};

export default TagCostVRN;
